zIndexLayers = {
  top: 100000,
  videoWidgetControls: 20000,
  notification: 2000,
  modalDialogDropdown: 1200,
  modalDialog: 1001,
  modalBackground: 1000,
  tooltip: 700,
  floatingDialog: 500,
  searchResults: 300,
  dropdown: 200,
  snapshotOverlaySelected: 151,
  snapshotOverlay: 150,
  buttonOverlay: 100,
  workbenchHeader: 30,
  workbenchMain: 20,
  sidebar: 15,
  handle: 10,
  bottom: 0
}

$layer(layerName)
  z-index: zIndexLayers[layerName]
