@require '/objects/buttons'
@require '/objects/context_menu'

.pika-single.is-hidden
  display: none

.pika-single
  $context-menu-base()
  $context-menu-arrow-top()
  width: 230px
  padding: 8px
  margin-top: 13px
  position: relative

.pika-title
  display: flex
  align-items: baseline
  margin-bottom: 6px

.pika-label
  display: inline-block
  overflow: hidden
  padding: 5px 3px 0 3px
  margin-bottom: 5px
  line-height: 20px
  position: relative
  $entry-editor__input-border()

  > select
    cursor: pointer
    position: absolute
    margin: 0
    left: 0
    top: 5px
    opacity: 0

.pika-prev,
.pika-next
  $button-reset()
  cursor: pointer
  width: 20px
  // hide text using text-indent trick, using width value (it's enough)
  text-indent: 20px
  white-space: nowrap
  overflow: hidden
  background-position: center
  background-repeat: no-repeat
  background-size: 60%

.pika-prev
  order: -1
  margin-right: auto
  background-image: url('/app/svg/chevron-blue.svg')
  transform: rotate(-90deg)

.pika-next
  margin-left: auto
  background-image: url('/app/svg/chevron-blue.svg')
  transform: rotate(90deg)

.pika-select
  display: inline-block

.pika-table
  width: 100%
  border-collapse: collapse
  border-spacing: 0
  border: 0

.pika-table th,
.pika-table td
  // One column per day
  width: (100% / 7)
  padding: 0

.pika-table th
  font-weight: normal

.pika-table
  font-size: 12px

.pika-table thead
  line-height: 25px
  text-align: center
  th
    background: $colors.element-light
  th:first-child
    border-radius: 3px 0 0 3px
  th:last-child
    border-radius: 0 3px 3px 0
  abbr
    border-bottom: none
    cursor: help

.pika-table .is-today
  font-weight: bold

.pika-button
  $button-reset()
  box-sizing: border-box
  padding: 5px
  width: 100%
  font-size: 12px
  line-height: 15px
  text-align: center
  border-radius: 3px
  .is-selected &
    background: $colors.element-light
  &:hover
    color: white
    background: $colors.blue-light
