.loading
  background-image: url('/app/images/ajax-loader.gif')
  background-position: center
  background-repeat: no-repeat
  background-size: 25px 25px
  width: 30px
  height: 30px
  img
    width: 50%
    vertical-align: middle
