$identity-image-size = 40px
$identity-well-padding = 10px
$identity-padding = 10px
$identity-image-padding = 10px
$identity-spacing = 10px
$identity-width = 140px

$user-detail-image()
  height: $identity-image-size
  width: $identity-image-size
  float: left
  margin-right: $identity-image-padding

.tab-iframe
  .user-details,
  .identities
    margin-bottom: 30px
  .user-details
    > img
      $user-detail-image()
      margin-bottom: 10px
  .identity-well
    max-width: 2*$identity-well-padding + 3 * $identity-width + (3 - 1) * $identity-spacing
    margin-bottom: 2 * $identity-well-padding
  .identity
    width: 200px
  .auth_provider
    width: $identity-width
  .identity,
  .auth_provider
    display: inline-block
    height: $identity-image-size + 2 * $identity-padding
    border: 1px solid $gray-light
    border-radius: 3px
    background-color: #EEE
    padding: $identity-padding
    position: relative
    margin-right: $identity-spacing
    img
      $user-detail-image()
    .uid,
    .auth_provider_name
      word-break: break-all
      $offset-left = $identity-padding + $identity-image-size + $identity-image-padding
      $stretch(false, $identity-padding, 20px, $offset-left)
      overflow: hidden
      text-overflow: ellipsis
    .remove
      .fa-close
        font-size: 1em
        color: $gray
        text-shadow: 0 1px 0 rgba(255,255,255,0.6)
        &:hover
          color: $gray-medium
          cursor: pointer
      $stretch($identity-padding, $identity-padding, false, false)
      opacity: 0
      transition: opacity 0.1s ease-in-out
  .identity:hover
    .remove
      opacity: 100
  .auth_provider
    color: inherit
    transition: box-shadow 0.1s ease-in-out
  .auth_provider:hover
    box-shadow: 0 1px ($identity-padding / 2) $gray
