$input-dimensions()
  font-size: inherit
  height: 33px
  line-height: normal
  box-sizing: border-box
  padding-top: 6px
  padding-bottom: 7px


$input-appearance()
  border: 1px solid $colors.light-gray
  // TODO Remove this when other input elements are migrated
  border-color: $metallic-gray
  border-radius: 2px

  background-color: white
