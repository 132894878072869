.asset-card
  $element-color = $colors.element-mid
  $element-background = alpha(white, 0.95)
  $tooltip-padding = 1em

  cf-thumbnail
    display: flex
    align-items: center
    justify-content: center
    min-width: 123px
    min-height: @min-width
    font-size: 50px
    color: $colors.element-dark
    background-color: $element-background
    border: 1px solid $element-color
    border-radius: 1px
    &[role="button"]
      cursor: pointer
    > img
      border-radius: 1px

  &__title
    margin-top: 0.4em
    width: 115px
    font-size: 14px
    line-height: 1.3
    // Allow 2 lines
    height: 2 * @line-height * @font-size
    text-align: center
    overflow: hidden
    color: $colors.text-mid
    // Text shadow for drag n drop visibility:
    text-shadow: 2px 2px 15px white, -2px -2px 15px white, 0 0 10px white, 0 0 1px white
    &[role=button]
      cursor: pointer

  &__tooltip
    padding: $tooltip-padding
    color: $colors.text-light
    background-color: $element-background

  &__action
    flex-shrink: 0
    padding-left: 0.3em
    padding-right: 0.3em
    cursor: pointer
    &, &:link
      color: $colors.blue-light
    &:hover
      color: $colors.blue-dark


  &__details
    min-width: 180px
    padding-bottom: $tooltip-padding
    // Line break after filename but squeeze all other info in 2nd line.
    // Whichever line is longer determines the container's width.
    > *
      white-space: nowrap
      display: inline
      &:first-child:after
        content: "\A"
        white-space: pre
      &:not(:first-child):not(:last-child):after
        content: ',\00a0'
