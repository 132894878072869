.ui-hint
  $layer('tooltip')
  position: absolute
  color: $colors.dark-gray
  background: $colors.ice-mid
  border: 1px solid $colors.ice-dark

  [data-arrow-direction]
    height: 14px
    width: 14px
    background: $colors.ice-mid
    transform: rotate(45deg)
    position: absolute
    border: 1px solid $colors.ice-dark

  [data-arrow-direction="up"]
    border-bottom-color: transparent
    border-right-color: transparent

  [data-arrow-direction="down"]
    border-top-color: transparent
    border-left-color: transparent

  [data-arrow-direction="left"]
    border-top-color: transparent
    border-right-color: transparent

  [data-arrow-direction="right"]
    border-left-color: transparent
    border-bottom-color: transparent

  cf-icon
    display: block

  .ui-hint-content
    font-weight: normal
    position: relative
    display: inline

  &__dismiss
    margin-left: 20px
    text-decoration: underline
    cursor: pointer
