@require '/variables/colors'
@require '/mixins/typography'
@require '/objects/buttons'
@require '/modules/entity_editor/controls'

// TODO Can be reused by the field setting’s widget selector
$circled-green-checkmark()
  transform: scale(0.8)
  margin-right: 1ex
  margin-top: -3px
  padding: 4px
  border-radius: 100%
  background: $colors.green-dark
  display: inline-block
  vertical-align: middle
  > svg path
    fill: white


.json-editor
  &__disabled-content
    $monospace-font()
    color: $colors.text-light
    background: $colors.element-lightest

    line-height: 1.5
    padding: 1em $rhythm(0.5)
    &.x--empty
      font-style: italic

  // We cannot use 'display: none' because CodeMirror will not render
  // anything when we display it again
  &__code-editor-container.ng-hide
    display: block !important
    visibility: hidden
    overflow: hidden
    height: 0

  &__toolbar
    display: flex;
    align-items: center
    padding: 8px;
    background-color: $colors.element-lightest

  &__title
    margin-right: auto
    color: $colors.text-light

  &__undo, &__redo
    $button-reset()
    $cfnext-button-layout()
    $button-secondary-colors()
    margin-left: 1ex
    padding: 0 1.5ex
    line-height: 24px
    flex: 0 1 auto;


  &__editor
    cursor: text
    padding-top: $rhythm(0.5)
    padding-bottom: $rhythm(0.5)
    $entry-editor__input-border()

    .CodeMirror
      height: auto
      color: $colors.text-dark
      $monospace-font()

    .CodeMirror-scroll
      min-height: $rhythm(6)

    .CodeMirror-vscrollbar
      overflow: hidden

  &__status-invalid
    line-height: $rhythm(2)
    color: $colors.red-dark
