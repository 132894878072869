/**
 * Styles for various form wigets
 */
.widget-datetime-editor
  input[type="text"] // TODO we can simplify this selector once we simplify form controls
    display: inline
  .fa-calendar,
  .fa-clock-o
    font-size: 14px
    color: $gray
    vertical-align: middle
    position: relative
    left: -25px
  .fa-clock-o
    pointer-events: none
  .cfnext-select-box
    margin-right: 1ex
