$cf-links-editor-shadow()
  box-shadow: 0 1.5px 1.5px $colors.element-light

.link-editor
  $links-gap = 12px
  position: relative;

  // TODO: Remove __new prefix this once feature-at-05-2018-sliding-entry-editor-multi-level
  // is rolled out to everyone
  &__feature-at-11-2017-lots-of-cts-add-entry-and-link-reference
    display: flex
    align-items: baseline
    margin-top: $links-gap

    .btn-link:not(:first-child)
      margin-left: 20px

  &__actions
    $cf-links-editor-shadow()
    display: flex
    > *
      flex-grow: 1
      padding: 1em
      border: 1px solid $colors.element-mid
      &:hover
        border: @border
      &:not(:first-child)
        margin-left: -1px

  &__links > li
    margin: 0

  [data-drag-handle] // TODO: Just have a global rule somewhere.
    color: $colors.text-light
    cursor: move
    cursor: -moz-grab
    cursor: -webkit-grab
    &:active
      cursor: -moz-grabbing
      cursor: -webkit-grabbing

  &:not(.x--contains-asset-cards)

    .link-editor__links
      > *
        margin-bottom: $links-gap
        &:last-child
          margin-bottom: 0

    .link-editor__links:not(:empty) + .link-editor__actions
      margin-top: $links-gap


  &.x--contains-asset-cards
    $links-gap-x = 15px
    $links-gap-y = 20px

    .link-editor__links
      position: relative
      display: flex
      flex-wrap: wrap
      left: -($links-gap-x)
      top: -($links-gap-y)
      width: "calc(100% + %s)" % $links-gap-x
      > *
        margin-left: $links-gap-x
        margin-top: $links-gap-y

.link-editor__create-asset-loading-spinner
  display: inline-block;
  position: relative
  top: 2px // For optical alignment
  height: 12px
  margin-right: 10px
  $spinner-base()
  +$spinner-active(black, 14px, 2px)
    display: block

.link-editor__warning
  ul
    margin-bottom: $rhythm(0.75)
  li
    list-style-type: disc
    margin-left: 20px
