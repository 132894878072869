/**
 * Classes that are common to many form widgets
 */


.form-widget__input-row
  position: relative
  margin-bottom: $rhythm(0.5)

// TODO Are these used?
.form-widget__help-text
  width: 80%
  color: $gray
  margin: $rhythm(0.5) 0

.form-widget__rights-info
  color: $colors.text-lightest
  font-size: 1.2em
