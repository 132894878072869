@require "../mixins/spinner"

$loader-base()
  display: flex
  align-items: center
  justify-content: center
  background: rgba(255,255,255, 0.7)

.loader
  $loader-base()
  height: 100%
  width: 100%
  position: absolute
  top: 0
  left: 0
  $layer('buttonOverlay')

.loader__container
  $loader-base()
  padding: 10px
  border-radius: 5em

.loader--inline
  position: static
  width: inherit
  background: transparent

.loader__spinner--inline
  $spinner-base()
  +$spinner-active()
    display: inline-block

.spinner--text-inline
  $spinner-base()
  +$spinner-active()
    display: inline-block
    width: .5em
    height: .5em
