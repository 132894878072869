@require 'mixins/typography'
@require 'variables/colors'

@require './api_content_model.styl'
@require './api_key_list.styl'

.api-key-boilerplate-instructions
  code
    $monospace-font()

  pre > code
    font-size: 12px
    $monospace-font()
    white-space: pre-wrap
    // overflow-wrap is CSS standard. Is implemented as word-wrap by IE
    overflow-wrap: break-word
    word-wrap: break-word

    display: block
    margin-bottom: 0.75em
    background-color: $colors.element-lightest
    border: 1px solid $colors.element-light
    padding: 0.75em 1em
