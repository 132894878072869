.code-block
  line-height: 0
  counter-reset: line
  color: $colors.text-mid
  position: relative
  border: 1px solid $colors.element-mid
  background-color: $colors.element-lightest
  padding: 8px 0
  white-space: pre
  overflow-x: auto
  flex-grow: 1
  flex-basis: 100%

  &__wrapper
    overflow-x: hidden
    display: inline-flex
    flex-wrap: nowrap
    vertical-align: middle
    width: 100%

  &__language
    position: absolute
    font-size: 10px
    text-transform: uppercase
    border: 1px solid $colors.element-mid
    right: -1px
    top: -1.5rem
    height: 1.5rem
    line-height: 1.5rem
    padding: 0 4px
    letter-spacing: 1px
    background-color: inherit

  &__line
    font-family: monospace
    display: block
    line-height: 0.6rem
    padding-right: 2em

  &__line:before
    user-select: none
    counter-increment: line
    content: counter(line)
    display: inline-block
    border-right: 1px solid $colors.element-mid
    padding: .5em 1em
    margin-right: .7em
    font-weight: bold
    color: $colors.text-lightest

  &__no-line-numbers
    .code-block__line:before
      visibility: hidden
      padding-left: 0
      padding-right: 0
      margin-right: 0

  // code highlighting
  .s1
    color: $colors.red-mid
  .s2
    color: $colors.green-dark

  &__copy-wrapper
    background-color: $colors.element-light
    border: 1px solid $colors.element-dark
    border-left: none
    padding-left: 1em
    padding-right: 1em
    padding-bottom: 5px
    display: flex
    align-items: center
