$loading-box()
  display: flex
  align-items: center
  justify-content: center

.loading-box
  $loading-box()
  border: 1px dashed $colors.element-darkest

.loading-box__spinner
  margin-right: 0.6em
  $spinner-base()
  +$spinner-active(black, 26px)
    display: block

.loading-box__message
  color: $colors.text-light

.loading-box--full-screen
  $loading-box()
  width: 100%

.loading-box--stretched
  $loading-box()
  z-index: 10
  position: absolute
  left: 0
  top: 0
  right: 0
  bottom: 0
  background: white
  transition: opacity 0.15s linear
  &.ng-enter
    opacity: 0
  &.ng-enter.ng-enter-active
    opacity: 1
  &.ng-leave
    opacity: 1
  &.ng-leave.ng-leave-active
    opacity: 0
