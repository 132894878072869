$modal-dialog-padding = 30px
$modal-dialog-header-padding = 23px

$modal-dialog-horizontal-padding()
  padding-left: $modal-dialog-padding
  padding-right: $modal-dialog-padding

$modal-transition()
  opacity: 0
  transition: .2s opacity ease-in-out
  .modal-dialog
    opacity: .5
    transform: scale(.85)
    transition: .3s opacity ease-in-out, .3s transform cubic-bezier(0.13, 0.62, 0.11, 0.99)
  &.is-visible
    opacity: 1
    .modal-dialog
      opacity: 1
      transform: scale(1)

.modal-background
  $stretch()
  $layer('modalBackground')
  $modal-transition()
  position: fixed
  overflow-y: auto
  height: 100vh
  text-align: center
  background-color: rgba($colors.contrast-dark, .75)

$modal-dialog-layout()
  $layer('modalDialog')
  display: inline-block
  position: relative
  min-width: 520px
  padding: 0
  text-align: left

$modal-dialog-chrome()
  background-color: white
  border-radius: 3px
  box-shadow: 0 2px 3px rgba(0,0,0,0.35)

.modal-dialog
  $modal-dialog-chrome()
  $modal-dialog-layout()

  .dropdown-menu,
  .context-menu
    $layer('modalDialogDropdown')

  &__header
    display: flex;
    align-items: center
    padding-top: $modal-dialog-header-padding
    padding-bottom: $modal-dialog-header-padding
    $modal-dialog-horizontal-padding()
    background-color: $colors.element-lightest
    border-radius: 3px 3px 0 0
    border-bottom: 1px solid $colors.element-dark

    > h1
      font-size: 1.2em
      flex-grow: 1
      margin: 0

  &__close
    color: $colors.text-light
    transform: translateY(3px)
    cursor: pointer
    background: none
    border: none

    &:after
      content: '×';
      font-size: 30px;
    &:hover
      color: $colors.text-mid

  &__content
    $modal-dialog-horizontal-padding()
    padding-top: $modal-dialog-padding
    padding-bottom: $rhythm(1)

  &__only-content
    $modal-dialog-horizontal-padding()
    padding-top: $modal-dialog-padding
    padding-bottom: $modal-dialog-padding

  &__controls
    display: flex
    align-items: center
    // TODO this overides the hideous 15px line height for modal
    // dialogs and makes button appearance consistent
    line-height: $base-line-height
    $modal-dialog-horizontal-padding()
    padding-bottom: $modal-dialog-padding
    // Optical vertical alignment with dialog text
    margin-left: -0.2ex
    margin-right: -0.2ex
    > * + *
      margin-left: 1rem

  &__plaintext
    white-space: pre
    max-width: 458px

  &__richtext
    color: $colors.text-mid
    line-height: 1.5;
    max-width: 458px

  &__highlight
    color: $colors.text-dark
    font-weight: 600

.modal-dialog
  &__hint
    color: $text-dimmed
    margin: $rhythm(1) 0 0

  &__message
    font-size: 1.14em
    margin-bottom: $rhythm(0.5)
    margin-top: 0.1em

$modal-dialog-slice()
  $modal-dialog-horizontal-padding()
  margin-left: - $modal-dialog-padding
  margin-right: - $modal-dialog-padding

.modal-dialog__slice
  $modal-dialog-slice()
