$editor-max-height = 500px
$markdown-radius = 2px

.markdown
  min-width: 490px

.markdown-tabs
  position: relative
  z-index: 10
  overflow: hidden
  margin-bottom: -1px

.markdown-tabs a
  color: $colors.text-mid
  display: block
  float: right
  padding: 8px 5px 4px
  width: 80px
  border: 1px solid $colors.element-dark
  border-top-left-radius: $markdown-radius
  border-top-right-radius: $markdown-radius
  margin-left: 3px
  text-align: center
  background-color: $colors.element-lightest
  border-bottom-color: $colors.element-lightest
  &.inactive
    background: $colors.element-light
    border-bottom-color: $colors.element-dark
    &:hover
      background: $colors.element-mid

.markdown-toolbar
  position: relative
  z-index: 5
  border: 1px solid $colors.element-dark
  background-color: $colors.element-lightest
  padding: 9px 7px 7px
  border-top-left-radius: $markdown-radius

.markdown-minor-actions
  margin-top: 5px

.toolbar-button
  background-color: transparent
  color: $colors.text-mid
  min-width: 30px
  height: 30px
  border: none
  display: inline-block
  border-radius: $markdown-radius
  cursor: pointer
  margin-right: 7px
  line-height: 1
  transition: all ease-in-out .1s
  svg path
    fill: $colors.text-mid //Quick fix!
  &:disabled
    cursor: not-allowed
    opacity: .35
  &:not(:disabled):hover
    background-color: $colors.element-mid

.markdown-headings
  padding-top: 3px
  margin-right: 2px
  font-size: 1em
  > i, > cf-icon, > svg
    display: inline-block

.markdown-insert-media > *
  display: inline-block

.markdown-more-actions,
.markdown-side-action
  border: 1px solid $colors.element-dark
  background: $colors.element-light
  &:not(:disabled):hover,
  &.active
    border-color: $colors.element-darkest
  &.active
    background: $colors.element-mid
    box-shadow: inset 0 1px 2px rgba(black, .12)

.markdown-side-action
  float: right
  padding: 0 8px
  font-size: 1em

/**
 * Editor, preview, info
 */

.markdown-areas
  border: 1px solid $colors.element-dark
  border-width: 0 1px
  overflow-y: auto

.markdown-editing-area
  textarea
    height: 1px

  .CodeMirror
    line-height: 1.5
    max-height: $editor-max-height
  .CodeMirror-lines
    color: $colors.text-mid
    padding: 20px
  .cm-header
    color: $colors.text-dark
  .cm-header-1
    font-size: 1.9em
  .cm-header-2
    font-size: 1.75em
  .cm-header-3
    font-size: 1.6em
  .cm-header-4
    font-size: 1.45em
  .cm-header-5
    font-size: 1.3em
  .cm-header-6
    font-size: 1.15em

  span.cm-tag,
  span.cm-string,
  span.cm-attribute
    color: $colors.red-dark
  span.cm-string
    text-decoration: none !important
  span.cm-quote,
  span.cm-comment,
  span.cm-variable-2
    color: $colors.text-light
  span.cm-link,
  span.cm-url
    color: $colors.blue-mid !important
  span.cm-link
    text-decoration: underline

.markdown-preview
  padding: 20px
  overflow-y: auto
  word-wrap: break-word
  overflow-wrap: break-word
  height: 100%
  max-height: $editor-max-height

.markdown-preview-crashed
  text-align: center

.markdown-help
  background: $colors.element-lightest
  border: 1px solid $colors.element-dark
  border-bottom-left-radius: $markdown-radius
  border-bottom-right-radius: $markdown-radius
  color: $colors.text-mid
  font-size: 0.85em
  padding: 5px 7px 4px
  cf-icon
    width: 25px
    height: 18px
    float: left
    margin-right: 5px

.markdown-info
  overflow: hidden
  font-size: 0.85em
  padding: 6px 2px 5px
  border: 0

.markdown-info__stats
  float: left

.markdown-info__requirements
  float: right

.markdown-marker__near
  color: lighten($orange, 20%)

.markdown-marker__invalid
  color: $colors.red-dark

/**
 * Markdown preview styles
 */

.markdown-preview
  .markdown-block
    margin: 0
  .markdown-block + .markdown-block
    margin-top: $rhythm(0.5)
  .markdown-block + .markdown-heading
    margin-top: $rhythm(1)
  .markdown-heading + .markdown-heading
    margin-top: $rhythm(0.7)
  .markdown-fragment
    display: inline
  .markdown-paragraph
    line-height: 1.5em
  .markdown-table
    width: 80%
    margin-left: auto
    margin-right: auto
  .markdown-image-placeholder
    border: 1px solid $gray-light
    border-radius: $markdown-radius
    padding: 10px
    background: $gray-lightest
  .markdown-image-placeholder img
    display: block
    margin: 0 auto
  hr
    border: 0
    background: transparent
    border-bottom: 1px solid $gray-light
  ul, ol
    margin: $rhythm(0.5) 0
    padding-left: 1.6em
  ul > li
    list-style-type: disc
  ol > li
    list-style-type: decimal
  table, td, th
    border: 1px solid $gray-light
  table
    table-layout: fixed
    border-right-width: 0
    border-bottom-width: 0
  th
    background: $gray-lightest
  td, th
    padding: 5px
    border-left-width: 0
    border-top-width: 0

/**
 * Special characters modal
 */

.markdown-special-char-choices
  max-width: 500px
  min-height: 300px

.markdown-special-char
  display: inline-block
  border: 1px solid $gray-light
  border-radius: $markdown-radius
  padding: 15px
  margin: 3px
  margin-top: 0
  margin-left: 0
  width: 50px
  font-size: 1.5em
  text-align: center
  cursor: pointer

.markdown-special-char.current
  background: $gray-lightest

.markdown-special-char-choice
  text-align: center
  margin: 20px
  p
    font-size: 2.5em
    font-weight: bold
    margin-bottom: 10px
  small
    font-size: 0.8em
    color: $gray-dark

/**
 * Misc dialogs
 */

.embed-content-dialog
  max-width: 500px
  input[type="text"]
    display: inline
  i.fa
    font-size: 14px
    vertical-align: middle
    position: relative
    margin-left: -25px
  .is-url-invalid
    color: $colors.red-dark
  .is-url-broken
    color: $orange
  .embed-content-dialog__suffix
    display: inline-block
    margin-left: 10px

/**
 * ZenMode
 */

.cf-zenmode
  position: fixed
  z-index: 100
  left: 0
  right: 0
  top: 0
  bottom: 0
  background: #FFF
  overflow: hidden
  .CodeMirror-lines,
  .markdown-preview-mounting-point
    max-width: 640px
    margin: 0 auto
  .markdown-preview
    max-height: none
  .CodeMirror
    max-height: none

.zenmode-logo
  z-index: 104
  position: fixed
  top: 13px
  left: 16px
  width: 30px
  height: 30px

.zenmode-toolbar,
.zenmode-footer
  z-index: 103
  position: fixed
  left: 0
  right: 0
  background-color: $colors.element-lightest

.zenmode-toolbar
  top: 0
  padding: 12px 20px 0
  padding-left: 60px
  height: 52px
  border-bottom: 1px solid $colors.element-dark

.zenmode-editor,
.zenmode-preview
  position: fixed
  top: 52px
  bottom: 38px
  width: 50%

.zenmode-editor
  z-index: 102
  left: 0
  border-right: 1px solid $colors.element-dark

.zenmode-preview
  z-index: 101
  right: 0

.zenmode-drawer__show,
.zenmode-drawer__hide
  position: fixed
  cursor: pointer
  z-index: 105
  top: 49%
  height: 30px
  background-color: $colors.element-lightest
  border: 1px solid $colors.element-dark

.zenmode-drawer__show
  right: 0
  border-right-width: 0

.zenmode-drawer__hide
  left: 50%
  border-left-width: 0

.zenmode-footer
  z-index: 103
  position: fixed
  display: flex
  justify-content: space-between
  left: 0
  right: 0
  bottom: 0
  height: 38px
  padding: 10px 20px
  border-top: 1px solid $colors.element-dark

.zenmode-help
  display: flex
  cf-icon
    width: 25px
    height: 18px
    margin-right: 5px

.zenmode-info,
.zenmode-reqs
  font-size: 0.9em

.zenmode-help,
.zenmode-reqs
  min-width: 450px

/**
 * Help
 */

.markdown-help-dialog
  a
    text-decoration: underline

.markdown-help-columns
  display: flex
  width: 720px

.markdown-help-column
  width: 340px

.markdown-help-column:first-child
  border-right: 1px solid $gray-light
  margin-right: 20px

.markdown-help-item
  width: 340px
  font-size: 1.5em
  margin-bottom: 15px
  line-height: 1.5em

.markdown-help__preview
  width: 170px
  display: inline-block

.markdown-help__markup
  width: 170px
  display: inline-block
  color: $gray

.markdown-help-link
  padding: 20px
  text-align: center
  a
    font-size: 1.1em

.markdown-insert-confirmation
  &__list
    margin-top: 15px

  &__list-element
    list-style: disc
    margin-bottom: 5px
