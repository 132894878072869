.search-results-paginator
  padding: 10px
  color: $colors.text-lightest
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center

  &__page
    margin-left: 10px
    padding: 10px
    cursor: pointer
    &:hover
      background: $colors.element-lightest

  &__prev, &__next
    text-decoration: none
    cursor: pointer
    &:hover
      color: $colors.text-dark
    &[aria-disabled=true]
      color: $colors.element-light
      cursor: default


  &__prev
    margin-right: 30px
    &::before
      content: '‹'
      margin-right: 5px
  &__next
    margin-left: 40px
    &::after
      content: '›'
      margin-left: 5px

  .x--active-page
    color: $colors.text-dark
    background: $colors.element-light

  .x--dots
    &:hover
      color: $colors.text-lightest
      cursor: default
      background: transparent
