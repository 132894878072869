.activation-email-resend-dialog
  max-width: 42em

  &__intro
    font-weight: bold

  &__resend-btn:disabled
    background-color: transparent
    color: $colors.text-light
    cursor: default

  &__error
    margin-bottom: 0!important
    color: $colors.red-light
