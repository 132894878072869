$app-top-bar__action-color()
  color: $colors.white
  &:hover
    color: $colors.blue-mid

.app-top-bar
  height: $nav-bar-height
  display: flex
  background-color: $colors.contrast-light

  &__sidepanel-trigger,
  &__account-menu-trigger
    background-color: $colors.contrast-mid
    transition: background-color 0.1s ease-in-out
    &:hover
      background-color: $colors.contrast-dark

  &__sidepanel-trigger
    height: 100%
    box-shadow: inset -1px 0 2px 0 rgba(0, 0, 0, 0.4), inset -2px 0 5px 0 rgba(0, 0, 0, 0.35)
    &:hover
      box-shadow: inset -1px 0 2px 0 rgba(0, 0, 0, 0.9), inset -2px 0 5px 0 rgba(0, 0, 0, 0.75)
    > *
      flex-shrink: 0
      flex-grow: 0

  &__account-menu-trigger
    box-shadow: inset 1px 0 2px 0 rgba(0, 0, 0, 0.4), inset 2px 0 5px 0 rgba(0, 0, 0, 0.35)
    &:hover
      box-shadow: inset 1px 0 2px 0 rgba(0, 0, 0, 0.9), inset 2px 0 5px 0 rgba(0, 0, 0, 0.75)

  &__with-right-part
    justify-content: space-between

  &__child
    height: 100%

  &__main-nav
    flex-grow: 1
    display: flex

  &__menu-trigger
    height: 100%
    display: flex
    align-items: center;
    justify-content: space-between
    cursor: pointer;
    $app-top-bar__action-color()
    > .triangle-down
      border: 4px solid $colors.white
      border-left-color: transparent
      border-right-color: transparent
      border-bottom-color: transparent
      transition: border-top-color 0.1s ease-in-out
    &:hover, &.is-active
      > .triangle-down
        border-top-color: $colors.blue-mid
  &__sidepanel-trigger
    cursor: pointer
    display: flex
    align-items: center
    padding: 0 15px
    line-height: 1.5
    width: 280px
    @media (max-width: $nav-bar-break-width)
      width: 260px

  &__action
    display: flex
    flex-direction: column
    justify-content: center
    height: 100%
    &:first-child
      padding-left: 15px
    & > a > cf-icon
      margin-right: 10px
    @media (max-width: $nav-bar-break-width)
      padding: 0px
      & > a > .nav-bar__list-label > cf-icon
        display: none
    a
      $app-top-bar__action-color()
      padding: 0 20px
    &:focus
      $focus-styles

  &--left-separator
    border-left: 1px solid transparentify($colors.white, 20%)
  &--right-separator
    border-right: 1px solid transparentify($colors.white, 20%)
