$entry-editor__input-border()
  // Set both properties to fix some browser inconsistencies
  border-width: 0 0 1px 0
  border-style: solid
  border-color: transparent
  border-image-width: 0 0 1px 0
  border-image-source: url("/app/svg/dotted-border.svg")
  border-image-repeat: round
  border-image-slice: 1 1

// Common base for <input> and <textarea>
$entry-editor__input-base()
  font-size: 16px
  color: $colors.text-dark
  $sans-font()

  display: block
  width: 100%
  line-height: 1.5
  padding: 0 2px
  background: transparent
  $entry-editor__input-border()

  transition: 0.2s linear
  transition-property: padding, background-color

  &[aria-invalid="true"]
    background: #FFF2F1

  &[aria-disabled="true"], &[disabled], &:disabled
    background: #F7F9FA
    color: $colors.text-lightest
    border-image: none


$entry-editor__input()
  $entry-editor__input-base()
  height: 36px
  // If the size property is set it determines the width
  &[size]
    width: auto

.entry-editor__input
  $entry-editor__input()

$entry-editor__textarea()
  $entry-editor__input-base()
  padding-top: 0.2em
  height: ($new-rhythm(3) + 0.3em)
  // Prevents horizontal user resizing
  max-width: 100%


.entry-editor__textarea
  $entry-editor__textarea()

@require '/mixins/spinner'

.entry-editor__input-group
  position: relative
  display: block
  height: 36px
  > input
    $entry-editor__input()
    padding-right: calc(18px + 0.8em)

  > cf-icon
    position: absolute
    right: 0
    top: 0
    bottom: 0
    width: 18px
    cursor: default
    svg
      position: relative
      top: 8px
      right: 2px

.entry-editor__input-spinner
  position: absolute
  top: 9px
  right: 0
  $spinner-base()
  +$spinner-active(black, 18px)
    display: block

@require '/objects/select_box'
.entry-editor__select-control
  $select-box-reset()
  $entry-editor__input-base()
  width: auto;
  padding-right: 1em
  padding-left: 0.5em
  height: 36px

  > option
    text-overflow: ellipsis
    overflow: hidden

  background-image:    $select-box-caret
  background-position: center right
  background-repeat:   no-repeat
  background-size:     0.7em
