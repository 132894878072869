.u-separator
  margin-top: 40px
.u-separator--small
  margin-top: 20px
.u-separator--large
  margin-top: 80px

$text-truncate()
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis

.u-truncate
  $text-truncate()

.u-locked-action,
.u-locked-action:link,
.u-locked-action i
  color: $colors.red-dark

.u-bce
  display: block

.u-ice
  display: inline

.u-list
  margin: 24px 0 24px 17px

.u-pull-right
  float: right

.u-flex-pull-right
  margin-left: auto

.u-word-break
  white-space: pre-wrap
  overflow-wrap: break-word
  word-wrap: break-word
  -ms-word-break: break-all
  word-break: break-all
  -ms-hyphens: auto
  -moz-hyphens: auto
  -webkit-hyphens: auto
  hyphens: auto


.vertical-form-field-rythm-dense
  margin-bottom: 28px;

.vertical-form-field-rythm-default
  margin-bottom: 40px;

