.cf-field-alert
  color: $orange

.cf-form-notice
  margin-bottom: 10px

.form-inline-error
  display: inline-block
  color: $orange
  padding-top: .3em
  font-size: .8em
  line-height: 1
  li
    margin-bottom: .3em

//TODO make sure this isn't used anywhere else
.form-box-error
  display: block;
  text-align: center
  padding: .6em $form-textarea-padding
  background: darken($orange, 3%)
  border: 1px solid darken($orange, 16%)
  color: white
  box-shadow: 0 1px 1px rgba(255,255,255,0.25) inset
  border-radius: 3px
  margin: 4px 0 4px 0
  width: $field-editor-width
  margin-right: auto
  transition: opacity 0.3s ease-in-out
  li
    margin-bottom: 0

.with-aux-panel
  .cf-form-box-error,
  [cf-field-editor],
  .field-editor,
  .content-type-field-list
    width: $field-editor-width + 18%
    max-width: 650px


