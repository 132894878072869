.upgrade-space
  &--container
    display: flex

  &--content
    flex: 1 1 65%

  &--graphics
    flex: 1 1 35%
    text-align: center
    padding-top: 10px

  &--text
    font-size: 14px
    line-height: 19px
    color: $colors.text-mid
    margin-bottom: 12px
    padding-right: 15px
