.user-link
  display: flex
  align-items: center
  padding: 12.5px 25px
  font-size: 14px
  line-height: 1.4

  &__unconfirmed
    color: $colors.text-light
    a
      color: $colors.text-light
      text-decoration: underline

.user-link > .entity-link
  &__image
    flex: 0
    width: 50px * 0.75
    min-width: @width
    height: @width
    border: 1px solid $colors.element-dark

  &__text
    width 100%
    > *:first-child
      font-size: 1em
    > *:not(:first-child)
      color: $colors.text-light
