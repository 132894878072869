@require '/variables/colors'

.structured-text
  position: relative;

  &--enabled .structured-text__toolbar {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 99;
  }

  &__editor {
    border-radius: 0 0 3px 3px;
    border: 1px solid $colors.element-dark;
    border-top: 0;
  }

  &__button
    border: 1px solid $colors.element-dark
    background: $colors.element-light
    padding: 0 8px
    &:hover,
    &.active
      border-color: $colors.element-darkest
    &.active
      background: $colors.element-mid
      box-shadow: inset 0 1px 2px rgba(black, .12)

  &__entry-link-block-button {
    margin-left: auto;
  }

  [data-slate-editor]
    padding: 20px

  .entity-link
    margin: 1.5em 0
    padding: 0 14px
    min-height: 100px
    cursor: pointer

  .entity-link--selected
    outline: auto

  .toolbar-toggle-heading-1 button span
    font-size: 1.625rem;
    font-weight: 700;

  .toolbar-toggle-heading-2 button span
    font-size: 1.4375rem;
    font-weight: 700;

  .toolbar-toggle-heading-3 button span
    font-size: 1.25rem;
    font-weight: 700;

  .toolbar-toggle-heading-4 button span
    font-size: 1.125rem;
    font-weight: 700;

  .toolbar-toggle-heading-5 button span
    font-size: 1rem;
    font-weight: 700;

  .toolbar-toggle-heading-6 button span
    font-size: 0.875rem;
    font-weight: 700;

  .toolbar-entry-dropdown
    margin-left: auto

  .toolbar-heading-toggle
    width: 140px;

    > span {
      padding: 0 2px;
    }

    svg
      margin-left: auto;

  .cf-slate-paragraph
    line-height: 1.5;
    margin-bottom: 1.5em;

  .cf-slate-mark-bold
    color: inherit;
    font-weight: 700;

  h1,h2,h3,h4,h5,h6
    &.cf-slate-heading
      font-weight: 600;
      line-height: 1.3;
      margin: 0 0 0.75rem 0;
      .cf-slate-mark-bold
        font-weight: 900;

  h1.cf-slate-heading
    font-size: 1.75rem;
  h2.cf-slate-heading
    font-size: 1.4375rem;
  h3.cf-slate-heading
    font-size: 1.25rem;
  h4.cf-slate-heading
    font-size: 1.125rem;
  h5.cf-slate-heading
    font-size: 1rem;
  h6.cf-slate-heading
    font-size: 0.875rem;

  .structured-text__editor
    blockquote
      margin: 0 0 1.3125rem;
      border-left: 6px solid $colors.element-light;
      padding-left: 0.875rem;

    code
      background: $colors.element-light;
      padding: 0px;
      color: $colors.text-mid;
      border-radius: 2px;
    a code
      color: inherit;

    // Un-Reset List Styles
    li ul, li ol {
      margin:0 0 0 1.5rem;
    }
    li {
      list-style: inherit;
      margin: 0;
    }
    ul, ol {
      margin: 0 0 1.25rem 1.25rem;
    }
    ul {
      list-style-type: disc;
    }
    ul ul {
      list-style-type: circle;
    }
    ul ul ul {
      list-style-type: square;
    }
    ol {
      list-style-type: decimal;
    }
    ol ol {
      list-style: upper-alpha;
    }
    ol ol ol {
      list-style: lower-roman;
    }
    ol ol ol ol {
      list-style: lower-alpha;
    }

    ul, ol
      .cf-slate-paragraph, span
        margin: 0;
        line-height: 1.5;

    hr
      height: 1rem;
      background: transparent;
      position: relative;
      margin: 0 0 1.5rem;
      &:hover
        cursor pointer;
      &:after
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background: $colors.element-mid;
        top: 50%;

    .hr--selected
      &:after
        background: $colors.blue-dark;
        box-shadow: 0px 0px 5px $colors.blue-dark;

  .structured-text__reference-card
    margin-bottom: 1.25rem;

  .structured-text__inline-reference-card
    margin: 0px 5px;
    font-size: inherit;

    span
      user-select: none

  .structured-text__inline-reference-card ul
    margin: 0px;

  .structured-text__hyperlink
    display: inline!important;

    &:hover,
    &:focus
      .structured-text__hyperlink-icon
        fill: $colors.text-dark;

  .structured-text__hyperlink-icon
    position: relative;
    top: 4px;
    height: 14px;
    margin: 0 -2px 0 -1px; // Optical alignment when using "trimmed" icon variant
    transition: fill 100ms ease-in-out;