/*
Usage

Adds an `:after` pseude element that displays a css spinner
~~~stylus
$color = black  // default
$diameter = 14px  // default

.with-a-spinner
  +$spinner-base()
    // custom styles

.with-a-spinner.x--active
  +$spinner-active($color, $diameter)
    // custom styles, e.g. margin
~~~
*/
$spinner-base()
  &:after
    content: ''
    opacity: 0
    border: 0
    width: 0

    position: relative
    top: -1px
    vertical-align: middle

    transition: width .2s linear,
                margin .2s linear
    {block}

$spinner-active($color = black, $diameter = $base-line-height - 4px, $border-width = 2px)
  &:after
    transition: width .2s linear,
                margin .2s linear,
                opacity .5s ease-in
    opacity: 1

    width: $diameter - 4px
    height: $diameter - 4px
    animation: fa-spin 2s infinite linear
    border-radius: 50%
    border-bottom: $border-width solid $color
    border-left: $border-width solid $color
    border-top: $border-width solid transparentify(lighten($color, 60%), 60%)
    border-right: $border-width solid transparentify(lighten($color, 60%), 60%)
    {block}

