.empty-state
  align-items: center
  display: flex;
  flex-direction: column
  height: calc(100% - 18px)
  justify-content: center
  margin: auto
  text-align: center

.empty-state__icon
  svg
    @media (max-height: 800px)
      height: auto
      width: 150px

.empty-state__title
  color: $colors.text-dark
  font-size: 1.7em
  font-weight: 600
  line-height: 1.2
  margin-top: 28px

.empty-state__description
  color: $colors.text-mid
  font-size: 1.3em
  line-height: 1.5
  margin: 20px auto 0
  max-width: 720px

.empty-state__action
  margin-top: 28px

.empty-state__additional
  color: $colors.text-light
  line-height: 1.5
  margin-top: 28px

.empty-state__note-box
  $note-box()
  $note-box--info()
  margin-top: 28px
  width: auto
