.gallery-buttons
  position: absolute
  top: 2px
  right: 2px
  padding: 3px 3px 2px
  border-radius: 5px
  background-color: transparentify(darken($metallic-gray-blueish, 40%), 0.7)
  .btn
    padding: 3px 5px
    color: white
    background: none
    border: none
  .fa
    font-size: 16px
