@require '/variables/colors'
@require '/modules/cfnext_form'

.tag-editor__input
  // Need to overwrite '.l-form-row' styles
  margin-bottom: $rhythm(1) !important

.tag-editor__pills
  &.x--disabled
    padding: $rhythm(0.5) 1em 0
    background: $colors.element-lightest

  // Placeholder to keep height
  &.x--empty:before
    content: ''
    width: 0
    height: 2rem
    margin-bottom: $rhythm(0.5)

.tag-editor__constraints
  font-style: italic
  margin-top: $rhythm(0.5)
  color: $colors.text-light
