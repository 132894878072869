.file-info
  position: relative
  height: 250px
  cf-thumbnail
    $stretch()
  .thumbnail
    cursor: pointer

  .file-preview,
  .file-metadata,
  .file-selector,
  .file-progress,
  .no-file
    background-color: $gray-lightest
    border: 1px solid $gray
    border-radius: 3px
    box-shadow: 0 1px 2px rgba(0,0,0,0.1) inset
    overflow: hidden
    $stretch()

  .file-preview,
  .no-file
    text-align: center

  .file-preview
    .icon
      font-size: 100px
      line-height: 250px

  .no-file
    line-height: 250px

  .file-metadata
    background-color: rgba(255, 255, 255, 0.8)
    padding: 10px
    th
      text-align: left
      padding-right: 1em

  .file-selector
    .__filestack-drop-pane-mount
      position: absolute
      top: 0
      bottom: 0
      left: 0
      right: 0
      z-index: 1
    .file-selector-btn-wrapper
      position: absolute
      bottom: 20px
      left: 0
      right: 0
      z-index: 2
      text-align: center

  .file-buttons
    $stretch(false, 10px, 10px, 10px)
    opacity: 0
    transition: opacity 0.1s ease-in-out
    display: flex
    > *
      margin-right: 0.5rem

.file-info:hover
  .file-buttons
    opacity: 1
