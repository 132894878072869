.nav-bar
  height: 100%
  user-select: none
  display: flex

  &__separator:not(:first-child)
    margin-top: 15px
    border-top: 1px solid $colors.element-mid
    padding-top: 15px

  &__separator:hover
    background: white
    color: inherit
    cursor: auto

  .tooltip-inner
    white-space: pre-wrap
    width: 200px

.nav-bar__list
  height: inherit
  display: flex

.nav-bar__list-item
  margin: 0

.nav-bar__link
  display: flex
  align-items: center
  height: 100%
  border-top: solid 3px transparent
  transition: color 0.1s ease-in-out
  .nav-bar__list-label
    height: 100%;
    display: flex;
    align-items: center;
    border-bottom: 3px solid transparent
    cf-icon
      margin-right: 10px;
  &:hover, &.is-active
    color: $colors.blue-mid
    .nav-bar__list-label
      border-bottom-color: $colors.blue-mid
    .app-top-bar__menu-trigger
      .nav-bar__list-label
        border-bottom-color: transparent
.app-top-bar__menu-trigger
  &:hover, &.is-active
    .nav-bar__list-label
        border-bottom-color: transparent
  &.is-active
    padding: 0 20px
  &.is-disabled, &.is-disabled:hover
    color: $colors.text-light
    cursor: not-allowed
