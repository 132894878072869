.webhook-list, .webhook-editor, .webhook-call
  .x--clickable
    cursor: pointer
  .x--ellipsis
    display: block
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
  .x--nowrap
    display: block
    white-space: nowrap
  code
    font-size: .9em

.webhook-editor .workbench-nav__tabs
  margin-left: $rhythm(1)

.webhook-call__header
  padding: $rhythm(1)
  display: flex
  justify-content: center

.webhook-call__header-item
  margin: 0 $rhythm(1)

.webhook-call .workbench-main__content
  position: relative
  top: 0
  left: 0
  right: 0
  bottom: 0

.webhook-call__column
  border-top: 1px solid $colors.element-mid
  position: absolute
  top: $rhythm(4)
  left: 0
  right: 50%
  bottom: 0
  padding: $rhythm(1.5)
  overflow-y: scroll

  strong
    display: block
    margin: $rhythm(1) 0
  strong:first-child
    margin-top: 0
  pre
    white-space: pre-wrap
    white-space: -moz-pre-wrap
    white-space: -pre-wrap
    white-space: -o-pre-wrap
    word-wrap: break-word

.webhook-call__column:last-child
  border-left: 1px solid $colors.element-mid
  left: 50%
  right: 0

.webhook-calls__paginator
  display: flex
  justify-content: center
  padding: $rhythm(1)

.webhook-calls__paginator-item
  display: block
  padding: $rhythm(.4)
  padding-top: $rhythm(.25)
  padding-bottom: $rhythm(.2)
  margin: 0 $rhythm(.3)
  background: $colors.element-light
  &.x--active
    background: $colors.blue-dark
    color: white
    font-weight: bold

.webhook-call__status-indicator
  display: inline-block
  width: 12px
  height: 12px
  border-radius: 6px
  margin-right: 6px
  &[data-status="success"]
    background: $colors.green-dark
  &[data-status="warning"]
    background: $colors.orange-dark
  &[data-status="failure"]
    background: $colors.red-dark

.webhook-editor__settings-section
  margin-top: $rhythm(1)
  padding-top: 0
  padding-left: $rhythm(1.5)
  padding-right: $rhythm(2.5)
  padding-bottom: $rhythm(1)
  border-top: 1px solid $colors.element-light

  .webhook-editor__settings-header
    display: flex
    align-items: center
    margin: 0
    margin-top: -13px
    margin-bottom: $rhythm(.7)

    > h3, button
      margin: 0
      margin-right: 10px
      padding: $rhythm(.2)
      background-color: white

    > button:hover
      background-color: white

    > button i
      margin-right: 10px

  .cfnext-form__field
    max-width: 65vw

    > label:first-child
      font-weight: bold
      span
        font-weight: normal
        color: $colors.text-light

    .webhook-header-action
      margin-top: $rhythm(.4)

  .webhook-editor__settings-option
    margin-bottom: $rhythm(.4)

  .webhook-editor__settings-row
    display: flex
    margin-bottom: $rhythm(.4)

    &:last-of-type
      margin-bottom: $rhythm(1)

    input
      flex-grow: 1

    > *
      margin-right: $rhythm(.4)

  #webhook-name, #webhook-content-type
    width: 75%

  .webhook-editor__settings_payload
    width: 75%
    margin-top: $rhythm(1)
    border: 1px solid #ddd
    border-radius: 4px

.webhook-editor__segmentation-table
  border-collapse: collapse
  margin: $rhythm(1) 0
  width: 100%

  label, input
    cursor: pointer

  .x--disabled-cell
    label, input
      cursor: not-allowed

  td, th
    font-weight: normal
    text-align: center
    padding: $rhythm(.5) $rhythm(.5) $rhythm(.4)
    border: 1px solid $colors.element-light

  td:first-child
    white-space: nowrap
    text-align: left
  .x--highlighted-cell
    background: $colors.element-lightest
  .x--empty-cell
    background: repeating-linear-gradient(
      45deg,
      $colors.element-lightest,
      $colors.element-lightest 10px,
      white 10px,
      white 20px
    )

.webhook-templates-dialog
  width: 800px
  &__content
    padding: 0

.webhook-templates-dialog-tabs
  display: flex
  flex-direction: row
  position: relative
  min-height: 517px
  &__separator
    position: absolute
    width: 1px
    top: 0
    bottom: 0
    left: 256px
    border-left: 1px solid $colors.element-dark
  &__list
    width: 256px
    min-width: 256px
  &__scroll-container
    position: relative
    display: block
    height: calc(100% - 38px)
    overflow: hidden
  &__scroll-container-inner
    position: absolute
    left: 0
    top: 0
    bottom: 0
    right: 0
    overflow-y: scroll
  &__list-title
    padding: 16px 20px 4px 20px
    font-size: 12px
    font-weight: 500
    text-transform: uppercase
    color: $colors.text-dark
    letter-spacing: 1px
  .webhook-template-item
    padding: 8px 16px
    cursor: pointer
    border-bottom: none
  &__pane
    flex-grow: 1
    padding: 24px 30px
    overflow: hidden

.webhook-template-form
  &__title
    font-size: 16px
    margin: 0 0 10px 0
  &__description li
    list-style-type: disc
    margin-left: 20px
  &__field
    margin-top: 24px
  &__field select
    display: block
    width: 100%
  &__field-description
    margin-top: 10px
  &__notice
    display: flex
    align-items: center
    margin-top: 40px
    svg
      margin-right: 5px
      font-size: 18px
  &__error
    margin-top: 10px
  &__actions
    margin-top: 20px
    button
      margin-right: 16px

.webhook-template-item
  height: 56px
  display: flex
  flex-direction: row
  align-items: center
  border-bottom: 1px solid $colors.element-light
  &__see-all
    justify-content: center
  &__logo
    width: 38px
    height: 38px
    background-color: $colors.white
    padding: 3px
    border-radius: 5px
  &__title
    padding: 0 20px
    flex-grow: 1
    strong
      display: block
      color: $colors.text-mid
      font-weight: 600
    small
      display: block
      color: $colors.text-mid
      font-weight: 300
  &--3-lines
    height: 70px
    small:last-child
      color: $colors.orange-mid
  &--active
    background-color: $colors.element-light

.webhook-secret-header-dialog
  &__input
    margin: $rhythm(1) 0
