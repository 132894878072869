.tab-iframe .formtastic
  max-width: 50em
  // remove list bullets
  .inputs,
  .actions
    li
      list-style-type: none
  .input
    @extend .cfnext-form__field
    input.form-control:not([type="checkbox"]):not([type="radio"]), textarea.form-control
      @extend .cfnext-form__input--full-size
    // not being @extend-ed properly
    textarea.form-control
      padding-top: 0.5em
      padding-bottom: 0.5em
      line-height: 1.3
      height: auto
    input[type="checkbox"],
    input[type="radio"]
      vertical-align: baseline
      width: auto
    .inline-hints
      @extend .cfnext-form__hint
    .inline-errors
      @extend .cfnext-form__field-error
  .input.info > div
    margin-left: $form-gutter
  .input > select
    margin-top: $form-offset
  .fragment
    padding-right: 10px
  .input.boolean
    .tooltip.top
      left: $form-labelwidth !important
  .input.table
    .result-list
      margin-top: $form-offset
      margin-left: $form-gutter
      width: 100% - $form-labelwidth
      float: left
      th
        padding-top: 0
      .form-control
        margin-left: 0
  .choices,
  .fragments
    .label label
      position: static
    .choice .form-control
      margin-left: 0
    .choices-group,
    .fragments-group
      margin-left: $form-gutter
      padding-left: 0
      margin-bottom: 0
      padding-bottom: 5px
      li
        margin-top: $form-offset

  fieldset.actions
    padding: 10px 0
    .action
      display: inline-block
    ol
      float: left
  .in-form-margins
    margin-left: $form-labelwidth
    width: $field-editor-width

  .actions-no-label
    padding-left: 20px

  .actions-hint
    margin: 0 1em
    font-size: $base-font-size


  > h1
    margin-left: 20px

$fa-offset()
  .fa
    margin-right: 4px

.btn--primary, .cfnext-btn-action
  $fa-offset()
  @extend .btn-action

.btn--secondary
  $fa-offset()
  @extend .btn-secondary-action
  &.new-resource
    margin-top: 34px //result list tr-height

.btn--confirm, .cfnext-btn-primary-action
  $fa-offset()
  @extend .btn-primary-action

.btn--danger, .cfnext-btn-caution
  $fa-offset()
  @extend .btn-caution

.cfnext-btn-link
  @extend .btn-link

.cfnext-btn-inline
  @extend .btn-inline

input.btn--input
  padding: 0.6em 1em

// All of the below styles are only referenced in the pre-zuora subscription views
// and should be removed soon. They are no longer used in production but are still
// necessary in GK dev mode for now
.toggle-button
  flex-grow: 1
  border-radius: 0
  &:first-child
    border-radius: 3px 0 0 3px
  &:last-child
    border-radius: 0 3px 3px 0
  cursor: pointer
  margin: 0
  &:active,
  &.active
    top: 0
    box-shadow: 0 2px 2px darken($gray-lightest, 10%) inset
    cursor: default
.button-icon-right
  position: relative
  right: 2px
  float: right
  top: 2px
  right: 0
  left: 3px

.validation-checkboxfield
  margin-bottom: 12px;
