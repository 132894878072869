$layout-field()
  padding-top: 10px
  padding-left: 20px

.layout-field--section-header
  $layout-field()
  text-align: center
  color: $gray-dark

.layout-field--info-text
  $layout-field()
  color: $gray

.layout-field--section-break
  background-color: $gray-dark
  height: 1px
  margin-left: 20px
  margin-right: 20px


