.ct-empty-list
  &__action
    margin: 1rem 0 2.4rem

  &__description
    margin-bottom: $rhythm(2)
    font-size: 1.33em
    line-height: 1.5

  &__additional
    font-size: 1.2em
    line-height: 1.5
