.space-home-page-contact-us
  &__container
    display: flex
    justify-content: space-between

  &__img
    width: 295px
    height: 104px
    background-size: contain
    background-image: url('/app/images/faces/contact-experts-space-home.jpg')
