// main-results and main-header are used for tables in the entity
// overviews

.main-results
  border-left: none
  border-right: none
  thead > tr > th:first-child,
  tbody > tr > td:first-child
    padding-left: 10px

.main-results-wrapper
  .main-results
    border-bottom: none
    border-top: none

// style changes for main-results, compared to regular result-list
.main-header.result-list
  border: 0
  border-bottom: 1px solid $gray-light

  tr
    height: 29px
  th, th button
    text-transform: uppercase
    letter-spacing: 1px

