@require 'Components/Notes'

.cfnext-advice
  min-height: 70vh
  max-width: 65em
  margin: 0 auto
  padding: $rhythm(2) 0
  text-align: center

  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  > *
    flex: 0 0 auto

.cfnext-advice-box
  $stretch()
  display: flex
  flex-direction: column
  justify-content: center
  &__frame
    margin: 0 auto
    text-align: center
    background-color: $colors.element-lightest
    border: 1px solid $colors.element-mid
    padding: $rhythm(1.5) $rhythm(2.5)
    min-width: 500px
  &__title
    font-size: 1.33em
    margin: $rhythm(0.33) 0 $rhythm(0.33)
  &__sub-title
    font-size: 1.1em
    margin-bottom: $rhythm(1)

// TODO deprecated
.advice
  $stretch()
  display: flex
  flex-direction: column
  justify-content: center

.advice--row-aligned
  flex-direction: row
  align-items: center

/*
Used for the advice items at the top level which act as content fallback in
case no spaces are available
*/
.advice-content-fallback
  top: $nav-bar-height

$advice-icon()
  color: $gray-medium
  text-shadow: 0 -1px 0px rgba(0,0,0,0.7)
  margin-bottom: 1rem

.advice__icon
  $advice-icon()

.advice__icon--normal
  font-size: 5em

.advice__icon--medium
  font-size: 9rem

.advice__icon--giant
  font-size: 14rem

.advice__icon--margin-right
  margin-right: 30px

.advice__icon--margin-bottom
  margin-bottom: 0.8rem

.advice__frame
  max-width: 60em
  margin: 0 auto
  display: flex
  flex-direction: column
  align-items: center
  text-align: center
  // IE11 hack
  > *
    max-width: 100%

.advice__frame--align-left
  align-items: flex-start
  text-align: left

.advice__frame--no-margin
  margin: inherit

.advice__title
  font-weight: bold
  font-size: 2em
  line-height: 1.2em
  margin-top: 0
  margin-bottom: $rhythm(0.5)
  i
    font-style: italic

.advice__title--normal
  font-weight: 600

.advice__description
  font-size: 1.2em
  line-height: 1.2em
  margin: 0 6em $rhythm(0.5)

.advice__subtext
  font-size: 1.2em
  line-height: 1.2em

.advice__action
  margin: $rhythm(1) 0
  $btn-action()

.advice__description--smaller
  font-size: 1.0em
  margin: 0 16em $rhythm(0.5)

.advice__note
  $note-box--info()
  margin: 0 0 $rhythm(2)
