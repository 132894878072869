$content-type-field-empty-style()
  border: 1px dashed $colors.light-gray
  background-color: lighten($colors.light-gray, 80%)

$ct-field-dimensions()
  padding-right: 1em
  height: $rhythm(2.5)
  margin-bottom: $rhythm(1)


.ct-field--placeholder
  $ct-field-dimensions()
  $content-type-field-empty-style()


.ct-field
  $ct-field-dimensions()
  display: flex
  align-items: center
  white-space: nowrap
  border: 1px solid $colors.light-gray
  border-radius: 1px
  box-shadow: 1px 1px 2px 0 $colors.light-gray
  background-color: white

  &.x--disabled, &.x--omitted
    // We need to explicitly overide the button color
    &, .btn-inline
      color: $text-dimmed

  &__name
    $text-truncate()
    font-weight: bolder
    margin-right: 1em

  &__icon
    margin-right: 0.66em

  &__type
    flex-grow: 1
    margin-right: 1em
    color: $text-dimmed

  &__status
    margin-right: 2em

  &__actions
    font-size: 0.6em
    margin-left: 0.5rem
    letter-spacing: 0.18em
    padding: 0.5rem

  &__actions-menu
    color: initial

  &__drag-handle
    width: 16px
    align-self: stretch
    margin: -1px
    margin-right: 1em
    background-color: $colors.aero-blue
    border: 1px solid $colors.shadow-green
    cursor: move
    cursor: -webkit-grabbing
    cursor: -moz-grab
    cursor: -moz-grabbing
    &.x--no-drag
      cursor: default
    .x--disabled &
      background-color: transparentify($colors.gray, 0.2)
      border: 1px solid transparentify($colors.green, 0.2)
    .x--omitted &
      background-color: transparentify($colors.red, 0.2)
      border: 1px solid transparentify($colors.red, 0.2)

  &__settings
    position: relative


.ct-fields--dummy
  .ct-field
    $content-type-field-empty-style()
    box-shadow: none
    color: $colors.gray
    &__drag-handle
      background-color: transparentify($colors.gray, 0.1)
      border: 1px dashed transparentify($colors.gray, 0.1)

  .ct-field:nth-child(0n+1)
    opacity: 0.5
  .ct-field:nth-child(0n+2)
    opacity: 0.4
  .ct-field:nth-child(0n+3)
    opacity: 0.3
  .ct-field:nth-child(0n+4)
    opacity: 0.2
  .ct-field:nth-child(0n+5)
    opacity: 0.1


.ct-no-fields-advice.advice
  .advice__frame
    width: 80ex
    padding: $rhythm(1) 1.5em
    $content-type-field-empty-style()
    border-style: solid
    border-radius: 3px
    text-align: center
  .advice__title
    font-size: 1.33em
    margin: $rhythm(0.33) 0 $rhythm(0.33)
  .advice__sub-title
    font-size: 1.1em
    margin-bottom: $rhythm(1)
  .advice__description
    font-size: 1.1em
    line-height: 1.5
    margin: 0
