@require '/variables/colors'

cf-radio-editor
  display: block

.radio-editor__options
  display: flex
  flex-direction: row
  flex-wrap: wrap
  &.x--vertical
    flex-direction: column
    align-items: start;
  &.x--disabled
    padding: 0 0.5em
    color: $colors.text-light
    background: $colors.element-lightest

.x--dir-rtl
  .radio-editor__options
    &.x--directed
      .radio-editor__label
        margin-right: 0.4em

.radio-editor__option
  cursor: pointer
  display: block
  line-height: $new-rhythm(1)
  margin: $new-rhythm(0.25) 1em $new-rhythm(0.25) 0
  // Overrides for 'l-form-row' styles !tableflip
  width: auto !important
  padding: 0 !important

.radio-editor__input
  vertical-align: middle
  margin: 0 0.6em 0 0

.radio-editor__clear
  display: block
  margin: $new-rhythm(0.4) 1.5em 0 0
  .x--vertical &
    display: inline-block
    margin: 0 1.5em
