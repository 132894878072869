.entity-selector__input-label
  display: flex
  justify-content: space-between

.entity-selector__search
  margin-bottom: 20px
  padding-right: 25px
  position: relative
  z-index: 1

.entity-selector__item-list
  height: 500px
  padding-top: 3px
  margin-top: -3px // Account for padding above, necessary to show highlighted top card's top border.
  overflow-y: scroll

  &-placeholder
    text-align: center
    margin-top: 100px
    color: $colors.text-light
    text-overflow: ellipsis
    overflow: hidden

.entity-selector__item
  cursor: pointer
  margin-bottom: $rhythm(.5)

  &.x--selected
    .entity-link, .asset-card
      background: $colors.element-light

.entity-selector__thumbnails
  position: relative
  display: flex
  flex-wrap: wrap
  align-items: flex-start
  align-content: flex-start
  left: -10px
  top: -10px

  .entity-selector__item
    margin-left: 10px
    margin-top: 10px
    border: 1px solid $colors.text-lightest
    padding: 5px

    &.x--selected
      background: $colors.element-light

.entity-selector__note
  margin-left: 0
  margin-right: 0


.entity-selector-dialog
  width: 66vw
  max-width: 720px

  &__change-selection-link
    float: right

// Special styling of "Users" entity selector.
.entity-selector-dialog--for-user-entities .entity-selector
  &__mode-switcher // TODO: Get rid of this general assumption that we don't want to show this.
    display: none

  &__input-label > p:first-child
    font-weight: bold
    margin-bottom: $rhythm(1)

  &__item-list
    height: auto!important
    min-height: 235px // TODO: Remove this as soon as we solve modal dialog re-positioning.
    max-height: 235px
    border: 1px solid $colors.ice-dark
    padding: 12.5px 0
    background-color: $colors.element-lightest
    &--empty
      background: transparent

  &__last-item .user-link
    padding-bottom: 25px

  &__item
    margin: 0
    background-color: $colors.element-lightest

    &.x--selected
      background-color: $colors.element-mid
      .user-link::after
        content: "\f058"
        color: $colors.green-dark

    &:hover
      background-color: $colors.element-light

    .user-link::after
      font-size: 18px
      font-family: FontAwesome
      content: "\f055"
      color: $colors.text-lightest

  .loading
    width: 100%
    box-shadow: inset 0 1px 2px $colors.element-mid
