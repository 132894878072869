cf-icon, .cf-icon
  display: block
  > svg
    display: block

cf-icon[name="plus"],
.cf-icon.cf-icon--plus
  circle
    fill: $colors.blue-mid

  &.inverted
    circle
      fill: white
    path
      stroke: $colors.blue-mid

cf-icon[name="nav-home"],
cf-icon[name="nav-ct"],
cf-icon[name="nav-entries"],
cf-icon[name="nav-media"],
cf-icon[name="nav-settings"],
cf-icon[name="nav-api"],
cf-icon[name="nav-user-settings"],
cf-icon[name="nav-spaces"],
cf-icon[name="nav-user-organizations"],
cf-icon[name="nav-user-oauth"],
cf-icon[name="nav-user-applications"],
cf-icon[name="nav-organization-information"],
cf-icon[name="nav-organization-subscription"],
cf-icon[name="nav-organization-users"],
cf-icon[name="nav-organization-billing"],
cf-icon[name="nav-usage"]
  path
    fill: white
    transition: all 0.1s ease-in-out

  a:hover &,
  .is-active &
    path
      fill: $colors.blue-mid

  .is-disabled:hover &,
  .is-disabled &
    path
      fill: $colors.text-light
