@require 'variables/colors'
@require '../mixins/spinner'

$entity-sidebar-content()
  $stretch(0, 0, 0, 0)
  padding: $rhythm(1) 1.6rem
  overflow-y: auto
  overflow-x: hidden


.entity-sidebar__info-panel
  $entity-sidebar-content()
  z-index: 1
  transition: transform .3s cubic-bezier(.38,.54,.5,.99)
  transform: translateX(100%)
  border-left: 1px solid $colors.element-darkest

  &.x--show
    // We need to hide the border
    transform: translateX(-1px)

// TODO rename workbench-sidebar
.entity-sidebar
// TODO rename workbench-sidebar__content
  $entity-sidebar-content()

  &__heading
    margin-top: $rhythm(2.5)
    font-size: .75rem
    font-weight: 500
    text-transform: uppercase
    color: $colors.text-light
    line-height: 2
    border-bottom: 1px solid $colors.element-dark
    letter-spacing: 1px
    margin-bottom: $rhythm(1.5)

  > .entity-sidebar__heading:first-of-type
    margin-top: 0

  &__state-select
    margin-bottom: $rhythm(0.5)


  &__state
    margin-bottom: $rhythm(0.5)
    color: $colors.text-mid

  &__state-indicator
    // TODO The alignment is similar to the spinner
    display: inline-block
    border-radius: 50%
    width: 14px
    height: 14px
    vertical-align: middle
    margin-bottom: 3px
    margin-right: 0.4rem
    &[data-state="archived"]
      background: $colors.red-light
      border: 1px solid $colors.red-dark
    &[data-state="draft"]
      background: $colors.orange-light
      border: 1px solid $colors.orange-dark
    &[data-state="published"]
      background: $colors.green-light
      border: 1px solid $colors.green-dark
    &[data-state="changes"]
      background: $colors.blue-light
      border: 1px solid $colors.blue-dark

  &__update
    width: 100%

  &__status-more
    color: $colors.text-light
    margin-top: $rhythm(1)
    display: flex
    align-items: baseline
    .context-menu.x--arrow-up
      &:before, &:after
        left: 90%

  &__save-status
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

  &__saving-spinner
    display: inline-block
    vertical-align: middle
    margin-bottom: 2px

    +$spinner-base()
      display: block

    &.x--active
      +$spinner-active()
        margin-right: 5px

  &__last-saved
    text-overflow: ellipsis
    overflow: hidden

  &__revert
    margin-left: auto
    flex-shrink: 0

  &__help-text
    margin-top: $rhythm(1)
    color: $colors.text-light

  &__preview
    i.fa-external-link
      margin-right: 6px
    &__toggle
      display: flex
      justify-content: space-between
      margin-top: $rhythm(1)
      &, a, strong
        color: $colors.text-light
    &__change
      text-decoration: underline
      cursor: pointer
      text-align: right
      min-width: 65px
    &__platform
      word-break: break-all
  &__no-users
    color: $colors.text-light

  &__widget
    margin-top: $rhythm(2)

  &__users
    display: flex
    flex-wrap: wrap
    > img
      display: block

  &__link
    cursor: pointer
    text-decoration: underline
  &__incoming-links
    color: $colors.text-light

  &__translation-pills {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__translation-pills-container {
    margin: 0px 8px 8px 0px;
  }

@require '../objects/pills'

.entity-sidebar
  &__translation-pill
    &.x--default
      background: $colors.element-light

  &__translation-remove
    $pill-item__remove()
    path
      fill: $colors.text-mid
    &:hover path
      fill: $colors.text-dark

.entity-sidebar__text-profile
  color: $colors.text-mid
  p, ul
    line-height: 1.5
    margin-bottom: $rhythm(0.5)
  ul
    margin-left: 15px
  ul > li
    list-style: disc
    margin-bottom: $new-rhythm(0.5)
