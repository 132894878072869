$select-box-reset()
  color: inherit
  font-size: inherit
  font-family: inherit
  cursor: inherit
  background: none
  line-height: normal
  border: 0
  appearance: none
  &::-ms-expand
    display: none


$select-box-caret = url("/app/svg/dd-arrow-down.svg")
$select-box-caret-disabled = url("/app/svg/dd-arrow-down-disabled.svg")
$select-box-hover-gradient = linear-gradient(to bottom, white, #F9F9F9)

.cfnext-select-box
  $select-box-reset()
  $input-dimensions()
  $input-appearance()
  padding-left: 1ex
  padding-right: 8ex

  text-overflow: ellipsis
  overflow: hidden
  white-space: nowrap

  background-image:    $select-box-caret, none
  background-position: center right 0.8em
  background-repeat:   no-repeat, repeat
  background-size:     0.7em, 100%

  &:hover:not(:disabled)
    background-image: $select-box-caret, $select-box-hover-gradient

  &:disabled
    // TODO abstract the colors
    background-color: hsl(0, 0%, 93%)
    background-image: $select-box-caret-disabled
    border: 1px solid $colors.light-gray
    color: hsl(0, 0%, 61%)
    cursor: not-allowed;

  &[aria-invalid=true]
    border-color: $colors.red-mid

.inline-select
  background: transparent
  color: inherit
  font-size: inherit
  margin: 0
  margin-top: -2px
  margin-left: 3px
  margin-right: 3px
