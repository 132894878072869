.view-role-selector__role
  display: flex
  padding: 12.5px 25px
  cursor: pointer
  user-select: none
  &.x--selected
    background-color: $colors.element-mid
  &:hover
    background-color: $colors.element-light
  &.x--disabled
    background-color: $colors.element-mid
    cursor: not-allowed
    color: $colors.text-light
