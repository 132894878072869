// Subscription plan pages

$subscription-yellow = #fffeec

.current-plan
  $pie-clearfix()
  margin-bottom: 3em
  background-color: $subscription-yellow
  padding: 20px
  .current-plan-name,
  .current-plan-price,
  .current-plan-billing
    width: 33%
    clearfix()
  .current-plan-name
    float: left
    padding-right: 20px
  .current-plan-price
    float: left
    padding-left: 20px
    border-left: 1px solid $gray-light
  .current-plan-billing
    float: left
    padding-left: 20px
    border-left: 1px solid $gray-light

.subscription-notice
  position: relative;
  border: 1px solid gray
  margin-bottom: 20px
  padding: 10px
  padding-right: 20px
  padding-left: 55px
  min-height: 60px
  &:before
    content: '\2139'
    font-family: 'SSStandard'
    display: block
    position: absolute
    left: 0
    font-size: 35px
    padding: 14px 18px
    vertical-align: top
    color: $gray

.plan-header
  font-size: 25px
  font-weight: bold
  line-height: 30px
  height: 30px
  color: $gray-dark
  &.muted
    color: inherit
    font-weight: normal

.plan-header-month
  font-size: 14px

.plan-superscript-header
  text-transform: uppercase
  letter-spacing: 1px
  font-size: 90%
.plan-subscript-header
  margin-top: .5em
  color: $gray-dark
  font-weight: bold
  font-size: 85%

.billing-frequency
  margin: auto
  width: 500px
  margin-bottom: 2em

.available-plans
  border-top: 1px solid $gray-light
.subscription-plan
  width: 100%
  border-bottom: 1px solid $gray-light
  padding: 15px 0 0 0
  clearfix()
  .col
    float: left
  .name
    padding-left: 20px
    width: 25%
  .spaces,
  .users,
  .entries
    text-align: center
    width: 10%
  .price
    text-align: center
    width: 25%
    color: $blue
  .aligned-number
    display: inline
  .actions
    width: 20%
    text-align: right
    padding-top: 10px
    padding-right: 20px
    .btn--secondary
      width: 100%
      max-width: 150px
      text-align: center
      line-height: 12px
    .fa-question-circle,
    .fa-info-circle
      position: relative
      top: 2px
.subscription-plan.disabled
  color: $gray
  .plan-big-row,
  .plan-small-row,
  .plan-medium-row
    color: $gray

$plan-detail-gutter = 20px
.plan-problem
  font-style: italic
.plan-big-row
  line-height: 30px
  font-size: 25px
  font-weight: bold
  color: $gray-dark
  .price &
    color: $blue
  .fa-info-circle
    font-size: 14px
    color: $gray
.plan-medium-row
  font-weight: lighter
  line-height: 18px
  font-size: 18px
.plan-small-row
  font-weight: lighter
  line-height: 14px
  font-size: $base-font-size
  color: $blue
.plan-details
  clear: both
  padding: 15px 20px 0
  .details-block
    display: inline-block
    width: 19%
    max-width: 130px
    min-width: 115px
    height: 60px
    text-align: center
    margin: 0
    vertical-align: bottom
  .details-feature
    font-size: 20px
    height: 20px
    .fa-check
      color: $blue
  .details-number
    font-weight: bold
  .details-period
    color: $blue
    text-transform: uppercase
    letter-spacing: 1px
    font-size: 85%
    font-weight: bold
  .details-show,
  .details-hide
    color: $gray-light
    margin: auto
    width: 50px
    height: 20px
    border: 1px solid $gray-light
    border-bottom-style: none
    text-align: center
    cursor: pointer
    line-height: 22px
    &:hover
      color: $gray
      background-color: $gray-lightest
  .details-show
    font-size: 15px
    line-height: 10px
.details-content
  display: flex
  margin-bottom: $plan-detail-gutter
  background-color: $gray-lightest
.plan-summary
  width: 15em
  margin: $plan-detail-gutter
  background-color: white
  li
    list-style-type: none
    line-height: 1.5
  i
    width: 25px
    display: inline-block
    text-align: center
    margin-right: 8px
  i.fa-plus
    font-size: 90%
    color: $gray
  i.fa-cloud
    position: relative
    top: 2px
    font-size: 150%
    color: $blue
    text-shadow: 0 0 2px black
.plan-properties
  flex-grow: 1
  margin: $plan-detail-gutter $plan-detail-gutter $plan-detail-gutter 0
  text-align: center
.plan-features,
.plan-limits
  background-color: white
  margin: 0
  padding-top: 24px
.plan-limits
  margin-top: $plan-detail-gutter
  
#enterprise-contact-form
  clear: both
  padding: 20px
  label
    text-transform: uppercase
    letter-spacing: 1px
    display: block
  input[type=tel]
    width: 20em
  textarea
    width: 100%
    height: 10em
  .buttons
    text-align: right
  input[type=submit]
    width: 150px
.enterprise-close-form-button
  color: $gray-medium
  font-size: 130%
  float: right
  &:hover
    color: $colors.dark-gray

.plan-questions,
.plan-cancel-account
  margin-top: 20px
  margin-bottom: 20px

.plan-questions
  margin-left: 20px
  float: left

.plan-cancel-account
  float: right
  margin-right: 20px
  text-align: right
  a
    color: darken($orange, 30%)


