.gallery-file
  width: 120px
  img
    display: block
  .fa
    display: block
    width: 120px
    line-height: 105px
    padding-bottom: 15px
    font-size: 50px
    text-align: center
    vertical-align: middle
    color: $metallic-gray-blueish

.gallery-file__no-file
  width: 120px
  height: 120px
  text-align: center
  vertical-align: middle
  padding: 33px 20px 0px
  word-wrap: normal


