// Overwrite some jQuery-UI stuff
.ui-corner-all
  border-radius: 3px  3px  3px  3px
.ui-widget-header
  background-image: none
  background-color: $gray-lightest
  color: $colors.dark-gray

.ui-autocomplete
  li
    list-style-type: none

html
  .ui-menu-item
    .ui-state-focus,
    .ui-state-active
      background-image: none

// Angular
.ng-hide:not(.ng-hide-animate)
  display: none

