.section-title
  // Overide h styles
  margin: 0 0 18px 0
  font-weight: bold
  line-height: 1

  display: flex
  font-size: 18px

  &:after
    margin-left: 10px
    content: ""
    height: 1px
    margin-top: 10px
    background-color: $colors.element-light
    flex-grow: 1

em.x--underline
  font-style: normal
  text-decoration: underline

$text-dimmed = hsl(0, 0%, 50%)
.text-dimmed
  color: $text-dimmed

.separated-links
  > *:not(:last-child):after
    content: "|"
    color: $colors.element-mid
    margin: 0 8px

