.table.locale-list__table
  height: auto

.locale-list__advice
  position: relative
  top: 10em

.locale-list__advice-frame
  width: 110ex
  padding: $rhythm(1) 1.5em
  margin-bottom: 1.5em
  border: 1px solid $colors.light-gray
  background-color: lighten($colors.light-gray, 80%)
  border-radius: 3px

  > div
    width: 100% // IE11 fix

  .advice__title
    font-size: 1.33em

  .advice__description
    font-size: 1.1em
    margin-left: 0
    margin-right: 0

.locale-editor
  width: 70%
  margin: 0 auto

.locale-editor__group
  margin-top: -0.5em
  border-top: 1px solid $colors.element-light
  padding: 1.5em 0 2em

  &-title
    display: inline-block
    margin: 0
    padding: 0
    padding-right: .3em
    background: white
    font-size: 1.3em
  h2
    margin-top: 1.7em
    font-size: 1.1em
  select
    width: 100%
  .note-box
    margin: 1.2em 0 0
  .cfnext-form-option__desc p,
  .locale-editor__setting p
    color: $colors.text-lightest
