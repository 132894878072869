.block-list
  max-height: 400px
  overflow-y: auto
  padding-left: 10px
  padding-right: 20px
  > li
    display: block
    position: relative
    list-style: none
    text-align: center
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
    padding: 10px
    color: $blue-link
    cursor: pointer
  > li:hover
    .close-button
      color: $gray-dark
  .close-button
    position: absolute
    top: 30%
    right: 10px
  .close-button:after
    font-size: 20px

