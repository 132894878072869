@require '../mixins/spinner'

.pill-list
  display: flex
  flex-wrap: wrap

  > .sortable-placeholder
    border: 1px dashed $colors.element-mid

  > *
    margin-bottom: $rhythm(0.5)
    margin-right: 1rem
    &:last-child
      margin-right: 0

.x--dir-rtl
  .pill-list.x--directed
    > *
      margin-right: 0
      margin-left: 1rem
      &:last-child
        margin-left: 0

$pill-loading()
  $spinner-base()
  &.is-loading
    +$spinner-active($colors.text-light, 14px, 1px)
      display: inline-block
      margin-left: 10px

$pill-item()
  $pill-loading()
  $text-truncate()
  position: relative
  color: $colors.text-mid
  padding: 1px 2ex 0 2ex
  border-radius: 2px
  line-height: 2rem
  // TODO Make this a variable. It is not part of the color scheme yet
  background: #EEF2F4

  > svg
    margin-left: 5px
    margin-right: -2px
    vertical-align: text-bottom
    max-width: 18px
    > path
      fill: white

.pill-item
  $pill-item()

  &--success,
  &--warning,
  &.is-loading
    display: flex
    align-items: center

  &--success
    background-color: $colors.green-dark
    color: white
  &--warning
    background-color: $colors.red-mid
    color: white

  &.x--draggable
    padding-left: 3ex

  &__drag-handle
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 3ex
    cursor: move
    cursor: -webkit-grabbing
    cursor: -moz-grab
    cursor: -moz-grabbing
    svg
      position: relative
      top: 8px
      left: 7px
    path
      fill: $colors.text-lightest

$pill-item__remove()
  $button-reset()
  float: right
  height: 2rem
  padding: 0 0.7rem
  // Bleed into .pill-item padding
  margin-right: -2ex

  svg
    height: $base-font-size
  path
    fill: $colors.text-lightest
  &:hover path
    fill: $colors.text-mid

.pill-item__remove
  $pill-item__remove()

.pill-item__text
  $text-truncate()
