.copy-to-clipboard
  color: $colors.text-mid
  a
    cursor: pointer
    text-decoration: underline
    color: $colors.text-mid
  .fa-check
    color: $colors.green-dark
    opacity: 0
    transition opacity .2s ease-in-out, margin-left .2s ease-in-out
    &.x--is-visible
      opacity: 1
      margin-left: 5px

  &.x--input-suffix
    background-color: $colors.element-light
    border: 1px solid $colors.element-dark
    border-radius: 0 3px 3px 0
    &:hover, &:active
      background-color: $colors.element-mid
