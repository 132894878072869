.incoming-links__list
  max-height: 143px
  overflow-y: auto

.incoming-links__item
  margin-left: 20px
  list-style-type: disc

.incoming-links__link
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: top
