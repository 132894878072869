.entity-status--published
  color: $green
  .status-bar
    background-color: $green

.entity-status--updated
  color: $blue
  .status-bar
    background-color: $blue

.entity-status--draft,
.entity-status--pending
  color: $gold
  .status-bar
    background-color: $gold

.entity-status--archived
  color: $orange
  .status-bar
    background-color: $orange


