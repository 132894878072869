.browser-tab
  background: #ccc
  border-radius: 4px 4px 0 0

  &--line
    display: flex
    height: 23px
    align-items: center

  &--dots
    display: flex
    margin-left: 5px
    position: relative
    top: 1px

  &--dot
    width: 9px
    height: 9px
    margin: 0 3px
    border-radius: 100%

    &__red
      background: #DF7166

    &__yellow
      background: #E6C646

    &__green
      background: #5CCC8B

  &--tab
    margin-left: 15px
    width: 110px
    height: 0
    border-bottom: 15px solid #e6e6e6
    border-left: 5px solid transparent
    border-right: 5px solid transparent
    align-self: flex-end

  &--padding
    background-color: #E6E6E6
    height: 21px
