$textarea-max-height = 400px

$form-control-theme()
  background-color: white
  box-shadow: 0 1px 2px rgba(0,0,0,0.1) inset
  transition: box-shadow 0.3s ease-in, border-color 0.3s ease-in

$form-control()
  $form-control-theme()
  padding: $form-textarea-padding
  $input-dimensions()
  border: 1px solid $metallic-gray
  border-radius: 3px 3px 3px 3px
  &:focus
    background-color: white
    border-color: $blue-link
    box-shadow: 0 0 3px lighten($colors.blue-mid, 50%) inset
  &.error-input:focus
    border-color: $colors.red-darkest
  &[aria-invalid="true"]
    border-color: $colors.red-darkest

input.form-control:not([type="checkbox"]):not([type="radio"]), textarea.form-control
  $form-control()

.form-control[disabled],
.form-control[readonly]
  background-color: $gray-lighter
  border: 1px solid $gray
  box-shadow: none
  transition: none
.form-control[disabled]
  color: $gray-medium
.form-control[readonly]
  color: $metallic-gray-dark

/**
 * @component form-control--with-unit
 */
.form-control--with-unit
  line-height: 31px
  height: auto
  padding: 0
  display: inline-flex
  > input
    height: 31px
    margin: 0;
    padding: 0 0 0 0.5em
    border: 0
    &[disabled]
      color: $gray-medium
      background-color: $gray-lighter
      box-shadow: none
      transition: none

.form-control__unit
  display: inline-block
  align-self: baseline;
  background-color: $gray-lighter
  padding: 0 0.5em;
  border-left: 1px solid $metallic-gray


textarea.form-control
  resize: vertical
  max-height: $textarea-max-height
  height: auto

input[type="number"].form-control
  padding-top: 8px
  padding-bottom: 8px

input.date.form-control,
input.time.form-control
  width: 130px

input[type="checkbox"].form-control
  margin-top: -1px
