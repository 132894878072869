@require "./stack"

$workbench-header-height = $tab-header-height
$workbench-header-horizontal-padding = 2em
$workbench-nav-height = 70px

.workbench
  display: flex
  flex-direction: column
  $stretch(0, 0, 0, 0)
  $layer('bottom')
  overflow-x hidden

.workbench-actions
  margin-top: auto
  display: flex
  justify-content: flex-start
  align-items: center
  > *
    margin-left: 0.7rem

$workbench-content()
  overflow-y: auto
  overflow-x: hidden
  position: relative
  padding-top: $rhythm(1)

$workbench-sidebar-width = 20%
$workbench-max-sidebar-width = 20rem
$workbench-content-width = 100% - $workbench-sidebar-width
$workbench-middle-width = 100% - (2 * $workbench-sidebar-width)

.workbench-main
  display: flex
  overflow-y: auto
  position: relative
  // 66px is the height of the header. This is admittedly ugly but needed for Safari
  // to calculate height correct. Setting it to 100% percolates a height value of its
  // parent without accounting for the adjacent header div that workbench-main has
  // in Safari. God bless our Lord and Savior, Apple.
  height: calc(100% - 66px)

  // Apply this modifier if `.workbench-main` does not contain a
  // sidebar or a `.workbench__xxx-content` element.
  &.x--content
    display: block
    $workbench-content()

  &.x--content-full-size
    display: block
    overflow-y: auto
    position: relative

  &__content
    $workbench-content()
    flex-grow: 1
    border-right: 1px solid $colors.element-darkest

  &__left-sidebar
    $workbench-content()
    padding-top: 0
    padding-right: 1.5em
    flex: 0 0 300px
    margin-right: auto

    @media (max-width: $nav-bar-break-width)
      flex: 0 0 280px

    // We only want to change the margin in sidebar
    // navigation
    .workbench-nav__tabs [role=tab]
      margin-right: 1rem


  &__right-content
    $workbench-content()
    width: 100%
    margin-left: auto
    height: 100%
    padding-right: 20px
    .table
      height: calc(100% - 86px) // results + paginator height
    .search-results__count, .resource-usage
      padding: 10px 20px
      padding-top: 0
      color: $colors.text-lightest
    .search-results__count
      float: left;
    .resource-usage
      float: right;
      &--warn
        color: $colors.orange-light
      &--danger
        color: $colors.red-light

  // Animated slide-in. Only used in API key editor
  &__info-sidebar
    $workbench-content()
    flex-shrink: 0
    background: $colors.element-lightest
    transition: all .3s cubic-bezier(.38,.54,.5,.99)
    &
      width: 0
      padding-left: 0
      padding-right: 0
      border: 0
    &.x--show
      padding: $rhythm(1) 1.6rem
      width: 30vw
      max-width: 30rem


  &__sidebar, &__entity-sidebar
    $workbench-content()
    z-index: 0
    flex: 0 0 22.5rem
    background: $colors.element-lightest
    overflow: hidden

    // Shadow, as if it were casted by &__content:
    &:after
      box-shadow: 1px 0 2px 0 rgba(black, 0.09)
      content: ''
      position: absolute
      top: -100px // Prevents round shadow corners.
      bottom: -100px
      left: -2px // 2px wide, otherwise shadow is cut off.
      width: 2px
      z-index: 1

  &__middle-content
    $workbench-content()
    width: $workbench-middle-width
    flex-grow: 1
    margin-left: auto
    margin-right: auto

.workbench-header__wrapper
  display: flex
.workbench-header
  border-bottom: 1px solid $colors.element-darkest
  border-top: 1px solid $colors.element-darkest
  display: flex
  flex-direction: row
  align-items: center
  background-color: $colors.element-lightest
  box-shadow: 0 1px 2px 0 rgba(black ,.09)
  padding-left: $workbench-header-horizontal-padding
  padding-right: $workbench-header-horizontal-padding
  position relative
  z-index: 6
  min-height: $workbench-header-height
  width: 100%
  flex-shrink: 0
  > *
    flex-shrink: 0
  &.x--with-nav
    display: block
    > *
      display: flex
    .page-header
      margin-top: 20px

  &__icon
    margin-right: 12px
    align-self: center

  &__title
    flex-shrink: 1
    text-overflow: ellipsis
    overflow: hidden
    font-size: $tab-header-font-size
    margin: 0 0.6em 0 0.1ex
    white-space: nowrap
    line-height: 30px

  &__left
    display: flex
    align-self: stretch
    width: 250px
    flex: 0 0 auto
    align-items: center

    @media (max-width: $nav-bar-break-width)
      width: 230px

  &__kb-link
    line-height: normal
    margin-left: 10px
    padding: 0 5px

  &__description
    $text-truncate()
    color: $gray
    flex-shrink: 1
    margin-right: 1ex
    margin-top: 3px

    & + .btn-inline
      position: relative
      top: 2px

  &__actions
    display: flex
    align-self: center
    margin-left: auto
    &-text
      align-self: center
    > *
      margin-left: 1em

  &__search
    display: flex
    align-items: center
    align-self: center
    position: relative
    flex-basis: 60%
    flex-shrink: 1
    max-width: 1100px
    padding-right: 20px
    > *:first-child
      width: 100%

.workbench-nav
  display: flex
  align-items: center
  justify-content: space-between
  height: 4rem
  border-bottom: 1px solid $colors.light-gray


.workbench-nav__tabs
  align-self: flex-end
  flex-grow: 0
  flex-basis: $workbench-content-width
  $tab-list()

@require "../mixins/spinner"

.workbench.x--center
  align-items: center
  justify-content: center

.workbench-loading
  &__spinner
    margin-bottom: $rhythm(1)
    $spinner-base()
    +$spinner-active()
      display: block
      width: 2em
      height: 2em
  &__message
    font-size: 2em

.workbench-forbidden
  &__over-headline
    font-size: 14px;
    margin-bottom: $rhythm(1)
  &__headline
    font-size: 28px
    font-weight: 500
    margin-bottom: $rhythm(1)
  &__message
    font-size: 20px
    text-align: center
    line-height: 1.4em
    margin-bottom: $rhythm(1)

// Temporary styles for stacking slide-in editors
// Part of "feature-at-05-2018-sliding-entry-editor-multi-level" feature flag

@keyframes slide-in-from-right {
  from { transform: translate3d(100px, 0, 0); }
  to { transform: translate3d(0, 0, 0); }
}

@keyframes slide-out-to-right {
  from { transform: translate3d(0, 0, 0); }
  to { transform: translate3d(100px, 0, 0); }
}

@keyframes offset-slide-out-to-right {
  from { transform: translate3d(200px, 0, 0); }
  to { transform: translate3d(300px, 0, 0); }
}

@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fade-out {
  from { opacity: 1; }
  to { opacity: 0; }
}

.workbench-layer {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: $colors.white;
  box-shadow: $box-shadow-workbench-layer;
  will-change: width;

  &:not(.workbench-layer--0) {
    // NOTE: Update EntityPageController PEEK_ANIMATION_LENGTH when updating animations here.
    transition: left 200ms ease-in-out, transform 200ms ease-in-out;

    &.ng-enter-active {
      pointer-events: none;
      animation: 200ms ease-in-out slide-in-from-right, 200ms ease-in-out fade-in;
    }

    &.ng-leave-active {
      pointer-events: none;
      animation: 200ms ease-in-out slide-out-to-right, 200ms ease-in-out fade-out;

      &.workbench-layer--offset {
        animation: 200ms ease-in-out offset-slide-out-to-right, 200ms ease-in-out fade-out;
      }
    }
  }

  .loader {
    // TODO: Remove z-index from .loader instead.
    z-index: 0
  }
}

.workbench-layer__editor-container {
  height: 100%;
}

.workbench-layer__overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background: $colors.contrast-dark;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: 200ms ease-in-out opacity;
}

.workbench-layer--is-current {
  // For performance, any layer below the top one keeps 100% width all the time
  // So the width won't change when adding new layers.
  width: auto;
}

.workbench-layer--offset {
  transform: translate3d(200px, 0, 0);
}

.workbench-layer__overlay:hover {
  opacity: 0.1 !important;
}

.workbench-layer:not(.workbench-layer--is-current) {
  &.workbench-layer--optimized:not(.workbench-layer--peeked) .workbench-main {
    display: none;
  }

  .loader, .workbench-main__sidebar {
    display: none
  }

  .workbench-header .breadcrumbs-widget,
  .workbench-header__icon {
    display: none;
  }

  .entity-editor__notification {
    display: none;
  }
}
