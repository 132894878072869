@require '/variables/colors'

.checkbox-editor__options
  display: flex;
  flex-direction: column;
  align-items: start;
  &.x--disabled
    padding: 0 0.5em
    color: $colors.text-light
    background: $colors.element-lightest
    // Include option margins
    overflow: hidden

.x--dir-rtl
  .checkbox-editor__options
    &.x--directed
      .checkbox-editor__label
        margin-right: 0.4em

.checkbox-editor__option
  display: block
  cursor: pointer
  line-height: $new-rhythm(1)
  margin: $new-rhythm(0.25) 0
  // Overide '.l-form-row' styles
  padding: 0 !important

  > input[type="checkbox"]
    vertical-align: middle
    // Overide user agent styles and fix alignment
    margin: -3px 0.6em 0 0
