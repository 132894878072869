.api-key-list
  margin-top: $rhythm(-1)
  &__reached-limit
    color: $colors.red-dark
  &__advice
    max-height: 246px

  // Empty state
  &__placeholder
    &:nth-child(1)
      opacity: 0.3
    &:nth-child(2)
      opacity: 0.2
    &:nth-child(3)
      opacity: 0.1
