@require '../mixins/spinner'

// Legacy classes for disabled buttons
.transparent-button-layer
  background-color: transparent
  position: absolute
  $layer('buttonOverlay')
  margin: 0 !important

.transparent-button
  background: none
  border: none
  cursor: pointer
  padding: 0
  margin: 0
.transparent-button[disabled]
  cursor: auto

$button-reset()
  color: inherit
  font: inherit
  font-size: inherit
  line-height: normal
  vertical-align: baseline
  padding: 0
  margin: 0
  border: 0
  background: none
  overflow: visible
  user-select: none
  cursor: pointer
  &::-moz-focus-inner
    border: 0
    padding: 0

button
  $button-reset()

$button($color, $text)
  $button-layout()
  $button-colors($color, $text)
  $button-loading($text)



/**
 * Applies layout and typography styles
 */
$button-vertical-padding = (10 / 16) * 1rem
$button-layout()
  display: inline-block
  padding: $button-vertical-padding 1.5rem
  cursor: pointer
  text-align: center
  font-size: .875rem
  font-weight: 500
  white-space: normal
  border: 1px solid transparent
  border-radius: 2px
  line-height: $base-line-height


$button-large()
  font-size: 1.15rem
  padding: .875rem 1.25rem

  > .btn-icon
    height: 0.8em

/**
 * Applies background, text and border colors for the various states.
 */
$button-colors($bg, $text)
  color: $text
  background: $bg
  background-size: 100% 200%
  transition-property: all
  transition-timing-function: ease-in-out
  transition-duration: 0.1s

  &:hover:not(:disabled)
    background-position: 0 100%

  &.active,&:active
    box-shadow: inset 0 1px 2px rgba(black, .12)


  // Reset for links
  &:link, a&:hover, a&:link, a&:visited
    color: $text

  &:disabled, &[aria-disabled=true], &.disabled
    opacity: 0.4
    cursor: default
    // This overides any other state styles, like hover or active
    background: $bg
    box-shadow: none


/**
 * Add a spinner pseude element and activate it in the `is-loading`
 * state.
 */
$button-loading($text)
  > *
    display: inline-block
  +$spinner-base()
    display: inline-block
  &.is-loading
    &:disabled
      opacity: 1
    +$spinner-active($text)
      margin-left: 5px

$btn-action-bg = linear-gradient(to top, $colors.blue-dark, $colors.blue-light)
$btn-action()
  $button($btn-action-bg, white)
  border-color: $colors.blue-dark

  &:hover, &.active
    border-color: $colors.blue-darkest

.btn-action,
.btn-action--large
  $btn-action()

.btn-action--large
  $button-large()

$btn-primary-bg = linear-gradient(to top, $colors.green-dark, $colors.green-light)
$btn-primary()
  $button($btn-primary-bg, white)
  border-color: $colors.green-dark

  &:hover, &.active
    border-color: $colors.green-darkest

.btn-primary-action,
.btn-primary-action--large
  $btn-primary()

.btn-primary-action--large
  $button-large()

$btn-secondary-text = $colors.text-mid
$btn-secondary-bg = $colors.element-light
$button-secondary-colors()
  $button-colors($btn-secondary-bg, $btn-secondary-text)
  border-color: $colors.element-dark

  &:hover:not(:disabled), &.active
    background-color: $colors.element-mid
    border-color: $colors.element-darkest

.btn-secondary-action,
.btn-secondary-action--large
  $button($btn-secondary-bg, $btn-secondary-text)
  border-color: $colors.element-dark

  &:hover, &.active
    background-color: $colors.element-mid
    border-color: $colors.element-darkest

  > cf-icon[name="plus"]
    circle
      fill: $btn-secondary-text
    path
      stroke: $btn-secondary-bg

.btn-secondary-action--large
  $button-large()

$btn-caution-bg = linear-gradient(to top, $colors.red-dark, $colors.red-light)
.btn-caution
  $button($btn-caution-bg, white)
  border-color: $colors.red-dark

  &:hover
    border-color: $colors.red-darkest

$button-plain()
  background: none
  $button-layout()
  color: $colors.blue-darkest
  text-decoration: underline
  &:hover
    color: $colors.text-dark

.btn-plain
  $button-plain()

$x--block()
  display: block
  width: 100%
  position: relative
  &:after
    position: absolute

  &.is-loading
    &:after
      // This overides the width and height transitions
      transition: opacity .2s linear
      top: 11px

.x--block
  $x--block()


// DEPRECATED
$btn-inline()
  font-size: inherit
  display: inline-block
  vertical-align: baseline
  padding: 0.1em 0.3em
  margin: 0
  border: 0
  background: none
  cursor: pointer
  color: $colors.blue

.btn-inline
  $btn-inline()

.btn-link
  $button-reset();
  display: block
  color: $colors.blue-dark
  transition: all ease-in-out .1s
  text-decoration: underline

  &:not(:disabled):hover
    color: $colors.text-dark
    background-color: transparent

  &:disabled, &--disabled
    cursor: not-allowed
    opacity: .5

  &:active, &--active
    box-shadow: none

  &__icon
    display: inline-block
    vertical-align: top
    margin-right: 0.4em
    > svg
      width: 18px
      height: 18px
      fill: $colors.blue-dark
    /*
    Partial Reference that transpiles to .btn-link:hover .btn-link__icon
    */
    ^[0]:hover &
      > svg
        fill: $colors.text-dark

$btn-icon()
  display: inline-block
  height: 0.9em
  margin-right: 0.55em
  > svg
    display: block

.btn-icon
  $btn-icon()

.btn-icon-left



$button--large-popout-icon()
  $button-large()
  padding-left: 5.0rem
  cf-icon
    position: absolute
    left: 1.0rem
    top: -0.5rem

.btn-action--large-popout-icon
  position: relative
  $btn-action()
  $button--large-popout-icon()

.btn-dropdown-icon
  float: right
  margin-left: 0.4em
  margin-right: -0.2em
  svg
    display: inline-block

.btn-action-select
  display: flex
  overflow: hidden
  border-radius: 2px

  &__selector,
  &__action
    $btn-primary()
    flex: 1 1 auto
    transition-property: all, width, padding
    transition-duration: 0.1s, 0.6s, 0.6s
    border-radius: 0

  &__action
    $x--block()
    border-right: none
    overflow: hidden
    width: 100%
    white-space: nowrap

  &__selector
    flex-shrink: 0
    width: 3.2rem
    white-space: nowrap
    overflow: hidden
    padding-left: 0
    padding-right: 0

  &__icon
    display: inline-block
    margin: 0 0.4em

  &__selector-label
    display: none


.btn-action-select.x--no-action

  .btn-action-select__action
    width: 0
    padding-left: 0
    padding-right: 0
    // This hides the border
    margin-left: -1px

  .btn-action-select__selector
    width: 100%

  .btn-action-select__selector-label
    display: inline
