.staff-hint
  color: $colors.text-light
  display: flex
  i
    margin-right: 12px

.staff-hint__content
  margin-bottom: 10px
  font-style: italic

.staff-hint__author
  display: flex
  align-items: center
  strong
    display: block
    color: $colors.text-dark
    padding: 4px 0 2px

.staff-hint__author-photo
  display: block
  margin-right: 10px
  width: 50px
  height: 50px
  background-image: url('/app/images/faces/herve.jpg')
  background-size: 100% auto
  background-repeat: no-repeat
  &.x--cesar
    background-image: url('/app/images/faces/cesar.jpg')
