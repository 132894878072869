.cfnext-form__field
  margin-bottom: $rhythm(1.5)
  > label
    display: block
    margin-bottom: $rhythm(0.5)

$cfnext-form-input()
  $sans-font()
  font-size: inherit
  border: 1px solid $metallic-gray
  border-radius: 3px 3px 3px 3px
  box-sizing: border-box
  height: 2.3em
  padding: 0 0.5em
  transition: border 0.3s
  &:focus
    background-color: white
    border-width: 1px
    border-color: $colors.blue
  &[readonly], &[disabled], &:disabled
    color: $colors.text-mid
    background-color: $colors.element-lightest
    border: 1px solid $colors.element-dark
    box-shadow: none
    transition: none
  &[aria-invalid="true"]
    border-color: $colors.red-dark
  &[type="number"]
    padding-right: 0
  textarea&
    padding-top: 0.5em
    padding-bottom: 0.5em
    line-height: 1.3
    height: auto

.cfnext-form__input
  $cfnext-form-input()
  max-width: 100%;

.cfnext-form__input--short
  $cfnext-form-input()
  width: 10ex;

.cfnext-form__input--full-size
  $cfnext-form-input()
  width: 100%;
  resize: vertical

.cfnext-form__hint
  margin-top: $rhythm(0.5)
  margin-bottom: 0
  color: $text-dimmed
  font-size: $small-font-size

.cfnext-form__hint--normal-text
  margin-top: $rhythm(0.5)
  margin-bottom: 0

.cfnext-form__label-hint
  margin-left: 1ex
  color: $text-dimmed
  font-size: $small-font-size

  &.x--invalid
    color: $colors.red-dark

.cfnext-form__field-error,
.cfnext-form__field-errors,
.cfnext-form__field-suffix-error
.cfnext-form__field-warning
  word-wrap: break-word
  margin-top: $rhythm(0.5)
  margin-bottom: 0
  color: $colors.red-dark
  // TODO overide because strong sets color !tableflip
  strong
    color: inherit

.cfnext-form__field-errors > li
  margin-bottom: $rhythm(0.5)

.cfnext-form__field-suffix-error
  display: inline
  margin: 0 0 0 1.5em
  color: $colors.red-dark
  // TODO overide because strong sets color !tableflip
  strong
    color: inherit

.cfnext-form__field-warning
  color: $colors.orange-dark

$cfnext-form__fieldset()
  legend
    width: 100%
    flex-shrink: 0
    margin-bottom: $rhythm(1)

.cfnext-form__fieldset
  $cfnext-form__fieldset()

.cfnext-form-option
  margin-bottom: $rhythm(1)
  display: flex

  .cfnext-form-option:last-child
    margin-bottom: 0

  > input[type="checkbox"],
  > input[type="radio"]
    align-self: flex-start
    flex-shrink: 0
    margin: 1px 0.5em 0 0

  > label
    display: block
    cursor: pointer

.cfnext-form-option__desc p
  font-size: $small-font-size
  margin: 0

.cfnext-form-option__desc label
  // Larger line height increases clickable area
  line-height:     $rhythm(1.5)
  // Negative margin makes sure the larger line height does not
  // influence the layout
  margin-top:    - $rhythm(0.5)
  display: inline-block;
  cursor: pointer

.cfnext-form-option > input[type="checkbox"]:disabled
  & + .cfnext-form-option__desc,
  & + label
    color: $text-dimmed

.cfnext-form__fieldset--horizontal
  $cfnext-form__fieldset()
  display: flex
  flex-wrap: wrap

  .cfnext-form-option
    margin-right: $rhythm(2)
  .cfnext-form-option:last-child
    margin-right: 0

.cfnext-form__input-group
  position: relative
  display: inline-block
  height: 2.3em

.cfnext-form__input-group--full-size
  position: relative
  display: block
  height: 2.3em

.cfnext-form__icon-suffix
  border: 0
  background: none
  cursor: pointer

  padding-left:  1.0em
  padding-right: 1.0em

  position: absolute
  top: 0; bottom: 0; right: 0;

  &[disabled]
    cursor: not-allowed
