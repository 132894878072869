$context-menu-top-bar--border = hsl(201, 33%, 66%)

$cfnext-caret-size = 12px

$context-menu-item--layout()
  line-height: 1.2
  padding: 0.6rem 2rem 0.6rem 1.3rem
  margin-bottom: 0
  white-space: no-wrap
  overflow-clip-box: content-box
  overflow: hidden
  text-overflow: ellipsis
  display: block
  text-align: left

$context-menu-item()
  $context-menu-item--layout()
  cursor: pointer
  color: $base-font-color

  &[disabled]
    cursor: default
    color: $colors.text-lightest
    background: transparent

    > *
      cursor: default

  &.selected
    background: $colors.blue-light
    color: white

  &:hover:not([disabled])
    background: $colors.blue-mid
    color: white

  &[aria-disabled]
    cursor: default
    color: $text-dimmed
    background: transparent


$context-menu-base()
  $layer('dropdown')
  min-width: 19ex
  position: absolute
  padding: 0.3rem 0
  border: 1px solid $colors.element-mid
  border-radius: 2px
  background: white
  box-shadow: 0px 1px 2px 0px hsla(black, 0.1);


$context-menu-arrow()
  &:after, &:before
    content: ''
    left: 50%
    border: solid transparent
    height: 0
    width: 0
    position: absolute
    pointer-events: none
  &:after
    border-width: 9px 5px
    margin-left: -5px
  &:before
    border-width: 11px 7px
    margin-left: -7px


$context-menu-arrow-top()
  $context-menu-arrow()
  &:after, &:before
    bottom: 100%
  &:after
    border-bottom-color: white
  &:before
    border-bottom-color: $colors.element-mid


$context-menu-arrow-bottom()
  $context-menu-arrow()
  &:after, &:before
    top: 100%
  &:after
    border-top-color: white
  &:before
    border-top-color: $colors.element-mid


[cf-context-menu]
  display: none

.context-menu
  $context-menu-base()

  &__items > *, &__item, [role="menuitem"]
    $context-menu-item()

  // FIXME this overides a lot of styles from $context-menu-item().
  // Maybe we should only use the [role="menuitem"] selector for the latter.
  hr
    margin: 0.6em 1.3em
    padding: 0
    background: $colors.light-gray
    cursor: auto
    &:hover
      background: $colors.light-gray


  &__items
    $scroll-vertically(80vh)

  &__header
    $context-menu-item--layout()
    color: $colors.text-dark
    margin: 0.3rem 0 0.1rem
    font-size: 0.85em
    font-weight: bold
    text-transform: uppercase
    letter-spacing: 1px

  &.x--arrow-down
    $context-menu-arrow-bottom()

  &.x--arrow-up
    $context-menu-arrow-top()

    &.x--manual
      margin-top: 13px

  // Right aligned top or bottom arrow
  &.x--arrow-right
    &:after
      left: auto
      right: 30px
    &:before
      left: auto
      right: 28px

  // Left aligned top or bottom arrow
  &.x--arrow-left
    &:after
      left: 28px
      right: auto
    &:before
      left: 28px
      right: auto

.entity-sidebar
  .context-menu
    max-width: calc(100% - 3.2rem)
