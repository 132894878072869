/**
 * Simple table styles where header and body are connected. Prefer this
 * over the complicated ones below.
 */
table.simple-table
  width: 100%;
  table-layout: fixed
  line-height: 1.5em

  th, td
    color: $colors.text-mid
    padding-left: 10px
    vertical-align: middle
    text-align: left
    &:first-child
      padding-left: 20px
    &:last-child
      padding-right: 20px
    &.x--image-cell
      width 120px
    &.x--xl-cell
      width: 36%
    &.x--large-cell
      width: 21%
    &.x--medium-cell
      width: 15%
    &.x--small-cell
      width: 12%

  td
    border-bottom: 1px solid $colors.element-light
    padding-top: 16px
    padding-bottom: 16px

  th
    background-color: $colors.element-lightest
    border-bottom: 1px solid $colors.element-mid
    font-weight: normal
    padding-top: 10px
    padding-bottom: 10px
    color: $colors.text-light

  tbody tr:hover
    background-color: $colors.element-lightest

/* Block */
.table
  height: 100%
  display: flex
  flex-direction: column
  line-height: 1.5em
  width: 100%

  /* Elements */
  table
    width: 100%
    table-layout: fixed
    background-color: white
    border-collapse: collapse

  th:first-child, td:first-child
    padding-left: 20px

  th:last-child, td:last-child
    padding-right: 20px

  th, td
    padding-left: 10px
    vertical-align: middle
    text-align: left
    img
      margin-right: 10px
    img
      height: 25px
    p
      width: 100%

  thead
    border-bottom: 1px solid $colors.element-mid
    tr
      background-color: $colors.element-lightest
      th
        padding-top: 10px
        padding-bottom: 10px
        color: $colors.text-light
        font-weight: normal
        span
          margin-left: 5px
  tbody
    tr
      color: $colors.text-mid
      &:hover
        background-color: $colors.element-lightest
      td
        border-bottom: 1px solid $colors.element-light
        padding-top: 16px
        padding-bottom: 16px
  .table__head
    flex-shrink: 0
    flex-basis: auto

  .table__body
    overflow-x: hidden
    overflow-y: auto
    td
      a
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
        display: block
        width: 100%
        color: $colors.text-mid


  .table__bulk-actions
    padding: 10px
    background-color: $colors.element-light
    border-bottom: 1px solid $colors.element-mid
    flex-shrink: 0
    flex-basis: auto
    a
      margin-left: 10px
      text-decoration: underline

  /* Modifiers */
  .x--image-cell
    width 120px

  .x--xl-cell
    width: 36%

  .x--large-cell
    width: 21%

  .x--medium-cell
    width: 15%

  .x--small-cell
    width: 12%

  .x--multiline
    > td
      vertical-align: top
      a
        white-space: normal
    > .td__checkbox-cell > div > label
      align-items: baseline
    p
      margin-bottom: 0

  .x--sortable
    &:hover
      cursor: pointer
      background-color: $colors.element-light

  .x--highlight
    background-color: $colors.ice-mid

  .x--active-sort
    font-weight: bold

  .x--success
    background-color: $colors.mint-mid


// Used by the entry and asset list.
.td__checkbox-cell, .th__checkbox-cell
  // Override .table ... td selector
  padding: 0 !important
  > div
    display: flex
    align-items: center
    > label
      flex-shrink: 0
      cursor: pointer
      align-self: stretch
      display: flex
      align-items: center
      padding-left: 20px
      padding-right: 10px
      > input
        cursor: pointer
.td__checkbox-cell > div > *
  padding-top: 16px
  padding-bottom: 16px
.th__checkbox-cell > div > *
  padding-top: 10px
  padding-bottom: 10px
