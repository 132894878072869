// Used only by gatekeeper
.deprecated-table
  width: 100%
  border-collapse: separate
  tr
    height: 40px
  td
    padding: 14px
  th
    padding: 0 14px
    background-color: $colors.element-lightest
    border-bottom: 1px solid $colors.element-mid
    text-align: left
    font-weight: normal
    color: $colors.text-light
    line-height: 40px
    &.x--asc, &.x--desc
      cursor: pointer
      &:after
        $caret()
        color: $colors.text-light
        margin-left: 3px
    &.x--asc:after
      content: "\f0d8"
    &.x--desc:after
      content: "\f0d7"
    &.x--selected
      font-weight: bold
  tbody
    color: $colors.text-mid
    tr:not(:last-child)
      > td
        border-bottom: 1px solid $colors.element-light
  &.x--hoverable
    tbody
      tr:hover
        cursor: pointer
        background-color: $colors.element-lightest
