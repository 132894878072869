.add-field-dialog
  &__list
    display: flex
    justify-content: center;
    margin-bottom: $rhythm(2)

  &__selection-link
    display: block
    text-align: center

  &__list-item
    width: 7em
    margin: 0 0.66rem
    text-align: center
    > h2
      font-size: 1em
      margin: $rhythm(1) 0 $rhythm(0.5)

  &__description
    font-size: 0.9em
    line-height: 1.3
    color: $colors.gray
    margin: 0

  &__icon
    $button-reset()
    width: 80px
    height: 80px
    margin: 0 auto
    text-align: center

    border: 1px solid $colors.light-gray
    box-shadow: 0 2px 2px rgba(0,0,0,0.1)
    &:active
      background: #FAFBFC
      box-shadow: inset 0 0px 3px 2px hsla(0, 0%, 0%, 0.05)
    > cf-icon
      margin: 0 auto;
      > svg
        display: inline-block

  &__config
    width: 54em

  &__name-inputs
    display: flex
    > *
      flex-basis: 50%
    > .spacer
      flex-grow: 0
      flex-basis: 3rem

  &__settings
    margin-bottom: $rhythm(1)

    .cfnext-form__fieldset
      max-width: 70ex

  &__setting-sections
    display: flex
    > *
      flex-basis: 50%
      flex-grow: 0
    > .spacer
      flex-shrink: 0
      align-self: stretch
      border-left: 1px solid $colors.light-gray
      flex-basis: 1.5rem
      margin-left: 1.5rem

    // We need to overwrite the ng-hide styles to make sure the
    // sections do not recalculate the widths
    > .ng-hide
      display: block !important;
      visibility: hidden

  &__note
    margin: $rhythm(1.5) 0
