.workbench-stack
  $stretch(0, 0, 0, 0)
  z-index: 100
  overflow: hidden
  transition: opacity 0.2s ease-in-out
  &.ng-enter, &.ng-leave-active.ng-leave
    opacity: 0
  &.ng-leave, &.ng-enter-active.ng-enter
    opacity: 1

.workbench-stack__content
  position: relative
  height: 100%
  background: $colors.white
  margin-left: 6em
  box-shadow: $box-shadow-workbench-layer;
  .ng-enter > &, .ng-leave-active.ng-leave > &
    // Transition needs to be only on ngEnter / ngLeave otherwise it causes a
    // weird Chrome layout bug in the bulk editor
    transition: transform 0.2s ease-in-out
    transform: translate3d(100px, 0, 0)
  .ng-leave > &, .ng-enter-active.ng-enter > &
    // Transition needs to be only on ngEnter / ngLeave otherwise it causes a
    // weird Chrome layout bug in the bulk editor
    transition: transform 0.2s ease-in-out
    transform: translate3d(0, 0, 0)

.workbench-stack__overlay
  $stretch(0, 0, 0, 0)
  background: $colors.contrast-dark
  opacity: 0.8
  cursor: pointer
