.search-field
  position: relative
  width: 100%
  height: 33px

.search-field__input
  $stretch(0, 35px, 0, 0)
  .form-control
    box-sizing: border-box
    width: 100%
    height: 100%
    font-size: $base-font-size
    border-top-right-radius: 0
    border-bottom-right-radius: 0
    border-right-width: 0
    -webkit-appearance: none
  .form-control::-webkit-search-cancel-button
    -webkit-appearance: none
  .form-control::-webkit-search-cancel-button::after
    display: block
    cursor: pointer
    font-family: 'FontAwesome'
    content: "\f057"
    color: $gray

.search-field__input--searching
  input.form-control:not([type="checkbox"]):not([type="radio"])
    padding-right: 30px

.search-field__button
  position: absolute
  top: 0
  right: 0
  bottom: 0
  padding: 0 7px 0 9px
  width: 35px
  font-size: $base-font-size
  line-height: $tab-header-field-height - 2px
  cursor: pointer
  border-top-left-radius: 0
  border-bottom-left-radius: 0
  border-color: $metallic-gray
  color: $metallic-gray-dark
  > .fa-search
    position: relative
    top: 1px
