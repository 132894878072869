$field-dialog-width = 60em
.field-dialog.modal-dialog
  padding: 0
  width: $field-dialog-width
  overflow: hidden

.field-dialog__header.modal-dialog__header
  padding-top: 0
  padding-bottom: 0
  border-bottom: 1px solid $colors.light-gray

  > h1
    line-height: ($rhythm(3) / 1.2em)
    display: flex
    $text-truncate()

  > cf-icon
    margin-right: 1em


.field-dialog__field-name
  $text-truncate()

.field-dialog__field-type
  margin-left: 0.6em
  margin-right: 1rem
  font-weight: normal
  color: $gray

.field-dialog__tab-list
  $tab-list()
  align-self: flex-end
  margin-left: auto

  [role=tab]
    line-height: 4.6rem
    .field-dialog__tab-invalid
      display: none
      width: 0
      padding-left: 0.3em

    &[aria-invalid=true] .field-dialog__tab-invalid
      display: inline-block


.field-dialog__content.modal-dialog__content
  padding-top: $rhythm(2)
  min-height: 30em

.field-dialog__widget-list
  display: flex
  flex-wrap: nowrap
  overflow: auto
  position: relative

.field-dialog__widget-default
  color: $colors.light-gray

.field-dialog__widget-item
  width: 130px
  height: 120px
  border: 1px solid $colors.light-gray
  margin: 0 0.95em 1em 0
  padding: 1px
  flex-shrink: 0
  position: relative
  display: flex
  flex-direction: column
  justify-content: flex-end
  text-align: center
  cursor: pointer
  &:hover,
  &.is-selected
    padding: 0
  &:hover
    border: 2px solid $colors.gray
  &.is-custom
    justify-content: space-between
  &.is-selected
    border: 2px solid $colors.blue-dark
    box-shadow: 0 8px 6px -6px hsla(black, 0.1)
    cf-icon[name="checkmark"]
      display: block
  p
    margin-bottom: 0.3em
    padding: 0 0.5em
  cf-icon[name="checkmark"]
    position: absolute
    right: -7px
    bottom: -7px
    display: none
    padding: 4px 3px
    border-radius: 10px
    background: $colors.blue-dark
    > svg path
      fill: white

.field-dialog__widget-item-header
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  border-bottom: 1px solid $colors.light-gray
  height: 30px
  color: $colors.gray
  text-transform: uppercase
  font-size: 0.9em
  padding: 0 8px
  margin-left: -1px
  margin-right: -1px
  text-align: left
  a
    color: $colors.gray
  a:hover,
  a:focus
    color: $colors.dark-gray

.field-dialog__widget-item-content
  flex-grow: 1
  display: flex
  flex-direction: column
  justify-content: flex-end
  padding: 0.5em
  padding-top: 0.7em

.field-dialog__widget-icon
  margin: auto 0
  svg
    display: inline-block

.field-dialog__widget-warning
  font-size: $small-font-size
  color: $text-dimmed
  margin: 0 0 $rhythm(1)
  > i.fa
    margin-right: 0.6em

.field-dialog__widget-options
  position: relative
  min-height: 19em
  padding-top: $rhythm(1.3)
  padding-bottom: $rhythm(1.3)
  background-color: $colors.white
  border-top: 1px solid $colors.light-gray
  border-bottom: 1px solid $colors.light-gray

  cf-icon[name="arrow-up"]
    position: absolute
    left: 2em // positioned at the center of the first widget above it
    top: -13px
    transform: scale(1.2)
    transition-property: margin-left
    transition-duration: 0.2s
    transition-timing-function: ease-in-out
    path
      fill: $colors.white
      stroke: $colors.light-gray
