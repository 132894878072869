$breadcrumbs-right-margin = 17px
$flex-row()
  display: flex
  flex-direction: row

.breadcrumbs-widget
  $flex-row()
  margin-right: $breadcrumbs-right-margin
  margin-left: -2em
  height: $workbench-header-height - 2px // Make allowance for top and bottom border on .workbench-header

.breadcrumbs-container
  $flex-row()
  overflow: hidden
  color: $colors.dark-gray

  .btn
    padding: 22px 18px 15px $breadcrumbs-right-margin
    cursor: pointer

    &:hover
      background-color: darken($colors.element-lightest, 3%)

    &__back
      border-right: 1px solid rgba(black ,0.06)

    &__ancestor
      padding-top: 30px
      padding-bottom: 30px

    &__active
      background-color: darken($colors.element-lightest, 10%)

  .ui-hint
    top: 55px
    box-sizing: border-box
    padding: 15px

    .ui-hint-content
      margin-bottom: 0

    [data-arrow-direction="up"]
      top: -7px

.breadcrumbs-container
  .breadcrumbs-ancestor-container
    border-right: 1px solid rgba(black ,0.06)

    .breadcrumbs-ancestor-list
      position: absolute
      display: none
      top: 48px
      left: 60px
      border-radius: 2px
      border: 1px solid $colors.element-mid
      box-shadow: 0px 1px 2px 0px rgba(black, 0.1)
      font-size: 1em
      background: white
      min-width: 160px
      max-width: 215px
      line-height: 45px
      $layer(dropdown)

      &::before
        content: ''
        display: block
        background: white
        height: 10px
        width: 10px
        position: relative
        top: -6px
        left: 13px
        transform: rotate(45deg)
        border: 1px solid rgba(black ,0.1)
        border-right-color: transparent
        border-bottom-color: transparent

      &__heading
        font-weight: bold
        padding: 0 20px
        margin-top: -13px

        [role="note"]
          margin-bottom: 0
          border-bottom: 1px solid rgba(black, 0.06)

      &__items
        margin-bottom: 4px
        max-height: 200px
        overflow-x: hidden

        li
          padding: 0 20px
          margin-bottom: 0
          overflow: hidden

          &:hover
            color: white
            background: $colors.blue-mid
            cursor: pointer

            g
              stroke: white
              stroke-width: 1px

            a
              color: white

          a
            white-space: nowrap
            display: block
            text-overflow: ellipsis
            overflow: hidden
            color: $colors.dark-gray

            cf-icon
              display: inline
              margin-right: 5px
              position: relative
              top: 3px

              svg
                display: inline
