.search-autocomplete-list
  display: table
  width: 100%

  li
    display: table-row

  li.autocompletion-row
    cursor: pointer

    &.selected,
    &:hover
      background-color: #F4F4F2
      .value-pill
        background-color: $colors.blue-darkest

    &:last-child
      .item-value,
      .item-description
        border: 0

  .item-value,
  .item-description,
  .header-value,
  .header-description
    display: table-cell
    height: 56px
    vertical-align: middle
    color: $colors.text-light
    border-bottom: 1px solid $colors.element-light

  .header-value,
  .header-description
    padding-top: 5px
    height: 40px
    font-size: .9em

  .header-value,
  .item-value
    padding-left: 27px

  .header-description,
  .item-description
    padding-right: 27px

  .value-pill
    display: inline-block
    padding: 6px 10px 5px
    margin-right: 27px
    border-radius: 2px
    background: $colors.blue-mid
    color: white
