add-padding()
  padding: 24px

add-bottom-border()
  border-bottom: 1px solid $colors.element-mid

remove-top-and-bottom-margin()
  margin-top: 0
  margin-bottom: 0

.tea-onboarding
  padding: 0

.tea-onboarding
  &__progress
    font-weight: bold

  &__pills
    display: inline-block
    margin-right: 10px

  &__pill
    display: inline-block
    border-radius: 100%
    width: 10px
    height: 10px
    background: $colors.light-gray
    margin-right: 2px

    &-active
      background: $colors.green-dark

.tea-onboarding__screenshot-container
  position: relative

.tea-onboarding__screenshot-image
  position: absolute
  top: 2px
  left: 4px
  background: url('/app/images/tea_screenshot.jpg')
  background-size: cover
  width: 240px
  height: 168px

.tea-onboarding__header
  padding: 20px 24px
  display: flex
  justify-content: space-between
  align-items: center
  add-bottom-border()

.tea-onboarding__heading
  line-height: 1.5em
  remove-top-and-bottom-margin()

.tea-onboarding__step
  display: flex
  flex-direction: column

.tea-onboarding__step-header
  display: flex
  flex-direction: row
  align-items: baseline
  cursor: pointer
  add-padding()
  add-bottom-border()

.tea-onboarding__step-heading
  flex-grow: 2
  remove-top-and-bottom-margin()

.tea-onboarding__step-toggle,
.tea-onboarding__alt-step-body
  color: $colors.blue-dark
  font-weight: bold
  .arrow
    width: 0
    height: 0
    display: inline-block
    position: relative
    margin-left: 7px
    display: inline-block
    border: 5px solid $colors.blue-dark
    border-top-color: transparent
    border-right-color: transparent
    border-bottom-color: transparent
    transition: transform 250ms ease-in
  .toggle
    transform: rotate(90deg)
    top: 3px

.tea-onboarding__step-header-icon
  svg
    height: 20px
    width: 24px
    position: relative
    top: 4px
  margin-right: 12px

.tea-onboarding__step-body
  background-color: $colors.element-lightest
  color: $colors.text-mid
  display: flex
  overflow-y: hidden
  max-height: 0
  padding: 0 60px
  box-sizing: border-box
  transition: all 250ms cubic-bezier(0.11, 0.31, 0.28, 0.96)
  > div:first-child
    width: 60%
    display: flex
    flex-direction: column

.tea-onboarding__step-body--expanded
  padding: 24px 60px
  max-height: 500px
  add-bottom-border()

.tea-onboarding__step-description
  flex-grow: 2
  width: 85%
  line-height: 19px
  h5
    margin-bottom: 1em
  .code-block
    margin-top: 0

.tea-onboarding__step-cta
  align-self: start

.tea-onboarding__step-graphic
  margin-left: 10px

.tea-onboarding__alt-step
  display: flex
  add-padding()
  padding-bottom: 25px

.tea-onboarding__alt-step-header
  display: flex
  flex-direction: row
  align-items: baseline
  flex-grow: 2
  h4
    margin: 0

.tea-onboarding__alt-step:first-child
  border-right: 1px solid $colors.element-mid

.tea-onboarding__alt-step-body
  display: block
  color: $colors.text-mid
  line-height: 25px
  display: flex
  align-items: center

.tea-onboarding__alt-step-cta
  display: block
  font-weight: bold
  color: $colors.blue-darkest
  cursor: pointer
  &:hover
    color: $colors.blue-darkest
