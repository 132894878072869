@font-face{
  font-family: "Avenir Next W01";
  src: url("/app/avenir-regular-normal.woff2") format("woff2"),
       url("/app/avenir-regular-normal.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face{
  font-family: "Avenir Next W01";
  src: url("/app/avenir-regular-italic.woff2") format("woff2"),
       url("/app/avenir-regular-italic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face{
  font-family:"Avenir Next W01";
  src: url("/app/avenir-medium-normal.woff2") format("woff2"),
       url("/app/avenir-medium-normal.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face{
  font-family:"Avenir Next W01";
  src: url("/app/avenir-demibold-normal.woff2") format("woff2"),
       url("/app/avenir-demibold-normal.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face{
  font-family:"Avenir Next W01";
  src: url("/app/avenir-demibold-italic.woff2") format("woff2"),
       url("/app/avenir-demibold-italic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

$sans-font()
  font-family: "Avenir Next W01", Helvetica, sans-serif

$monospace-font()
  // This is duplicated in `src/javascripts/Styles/index`
  font-family: "Menlo", "Andale mono", monospace

$establish-baseline()
  font-size: $base-font-size
  line-height: $base-line-height

$rhythm(n = 1)
  return n * unit($base-line-height / $base-font-size, em);

$new-rhythm(n = 1)
  return n * unit(1.5, em);
