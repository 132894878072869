.paywall
  &__header,
  &__content
    padding-left: 40px
    padding-right: 40px

  &__header
    padding-top: 20px
    > h1,
    > i
      display: inline-block
      vertical-align: middle;
    > h1
      font-size: 2em
      margin: 0 0 1px 0;
      color: $colors.text-dark
      &:after
        content: "."
    > i
      font-size: 3.9em
      margin-right: .3em
      color: $colors.red-light
    > .modal-dialog__close
      position: absolute
      top: 15px
      right: 15px

  &__content
    display: flex
    padding-top: 20px
    padding-bottom: 30px

  &__text
    flex: 3
    max-width: 460px
    color: $colors.text-mid
    > button
      margin: 3px 0 50px 0
    p
      margin-bottom: 1.6em
      font-size: 1em
  p&__intro
    font-size: 1.2em
    font-weight: bold
    line-height: 1.5
  p&__bottom-note
    font-size: 0.9em
    font-style: italic
    color: $colors.text-lightest
    margin-bottom: 0
    > a
      cursor: pointer

  &__recommended-subscription
    flex: 2
    max-width: 220px
    min-width: 160px
    margin-left: 4em
    margin-top: -50px
    > h2
      font-size: 1em
      font-weight: normal
      text-align: center
      margin-bottom: 1.5em
      color: $colors.text-light

  .z-subscription-plan__actions
    display: none !important
