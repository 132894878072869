.entity-info-panel
  color: $colors.text-mid
  background: $colors.element-lightest

  h2
    margin-bottom: $rhythm(1)
    font-weight: bold
    font-size: 1.2em
  table
    width: 100%
    border-collapse: collapse
    border-spacing: 0
    td, th
      width: 100%
      float: left
      display: block
      text-align: left
    th
      user-select: none
      margin-bottom: $rhythm(0.5)
      color: $colors.text-light
      font-size: .75rem
      font-weight: normal
      letter-spacing: 1px
      text-transform: uppercase
      line-height: 2
      border-bottom: 1px solid $colors.element-dark
    td
      margin-bottom: $rhythm(1.33)
