@require './*.styl'

.entity-editor-form
  padding-left: 2rem
  padding-right: 2rem

.entity-editor__notification
  line-height: 1.5
  padding: $rhythm(0.66) 2rem 0
  border-bottom: 1px solid #D0A2A0
  background: #FBE3E2
  margin-top: 0
  margin-bottom: $rhythm(1)
  color: $colors.red-dark
  p
    margin-bottom: $rhythm(0.66)

.sticky-parent
  padding-top: 0;
