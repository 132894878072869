.usage__period-selector
  display: flex
  & > label
    margin: 0.75rem 1rem 0 0


$overage-color = #FCA013

.usage-page
  display: flex
  flex-direction: column
  max-width: 1280px
  margin: 0 auto
  &__section, &__asset-bandwidth-section
    margin: 2rem
  &__section
    display: flex
    flex-direction: row
    justify-content: space-between
  &__asset-bandwidth-section
    border-top: solid 1px $colors.element-mid
    padding: 59px 0
  &__total-usage
    font-size: 2.2rem
    line-height: 3rem
    margin-top 1rem
  &__limit
    font-size: .9rem
  &__included-limit
    color: #abbbc2
  &__overage
    color: $overage-color
  &__learn-more-link
    margin-left: 10px
  &__chart-info
    h3
      color #9AACC2
      margin-top 0
      margin-left: 1rem
    h3, h2
      display: inline

  &__org-chart-tooltip, &__api-chart-tooltip
    border-radius: 2px
    font-family: "Avenir Next W01"
    font-weight: 600
    padding: 12.5px
    font-size: 14px
    .date
      margin-bottom: 12px
  &__org-chart-tooltip .value
    font-size: 21px
    line-height: 21px
  &__api-chart-tooltip
    background-color: #263545
    .value
      font-size: 14px
      line-height: 24px
      display: flex
      align-items: center
    .icon
      margin-right: 8.5px

  &__api-table
    margin-top: 1.5rem
    font-size: 0.9rem
    td
      padding: 1rem 1.5rem
      padding-left: 0
    tr:not(:last-child)
      td
        border-bottom: solid 1px #E5EBED
  .pill
    border-radius: 2px
    background-color: #E5EBED
    font-size: 0.7rem
    font-weight: bold
    letter-spacing: 1px
    padding: 0.1rem 0.3rem
    margin-left: 0.6rem
  &__space-usage, &__space-name
    color: #536171
  &__percentage-of-total-usage
    font-weight: bold
  &__no-spaces-placeholder
    grid-column-end: span 2
    font-weight: 600
    font-size: 2rem
    line-height: 1.2em
    width: 100%
    text-align: center
    position: absolute
    top: 40%

.resource-list
  margin: 40px

  &__title
    margin: 40px 0 20px

  &__highlights
    display: flex
    flex-direction: row
    justify-content: space-between
    flex-wrap: wrap

  &__item
    border: 1px solid $colors.element-mid
    box-shadow: 0 1px 2px 0 hsla(black, 0.1)
    margin: 0 0 16px
    display: flex
    flex-direction: column
    height: 60px
    justify-content: space-between

    &__column
      display: flex
      flex-grow: 1
      align-items: center

    &__content
      display: flex
      align-items: center
      height: 60px;
      padding: 0 20px;

    &__title
      margin: 0

    &__description
      font-size: $base-font-size
      color: $colors.text-light
      margin-left: 10px
      font-weight: normal

    &__icon
      margin-right: 10px
      margin-bottom -3px

    &--highlight
      height: auto
      flex-grow: 1
      margin-right: 20px
      padding: 20px
      &:last-child
        margin-right: 0
      .resource-list__item
        &__usage
          font-size: 40px
          line-height: 1
          font-weight: bold

// the following showld be global/common
.btn-actions-nav
  font-size: .6em;
  letter-spacing: 0.18em

  &[disabled]
    color: $colors.light-gray
    cursor: default
