.content-preview-list
  margin-top: $rhythm(-1)
  &__advice
    max-height: 246px
  &__placeholder
    &:nth-child(1)
      opacity: 0.3
    &:nth-child(2)
      opacity: 0.2
    &:nth-child(3)
      opacity: 0.1

.content-preview-editor
  padding: $rhythm(2) 2.5em 0
  &__input
    width: 480px
  &__checkbox
    margin-right: 10px
  .note-box
    margin: 0

.content-preview-sidebar
  line-height: 1.5em
  &__token
    color: $colors.green-dark
    font-weight: lighter
    box-sizing: border-box
    border: 1px solid $colors.element-mid
    padding: 2px
    border-radius: 2px
  &__token__alt
    color: $colors.orange-dark
