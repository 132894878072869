.modern-stack-onboarding

  &--container
    z-index: 113
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    background: $colors.element-lightest
    overflow-y: auto
    padding: 43px 65px

  &--wrapper
    margin: 15px auto
    max-width: 1500px

  &--header
    display: flex
    justify-content: space-between
    align-items: center
    margin: 0 0 30px

  &--title
    text-align: center
    margin: 35px auto 100px
    font-size: 44px
    font-weight: 500
    line-height: 46px
    max-width: 1000px

    &__margin
      margin-top: 150px

    &__small
      font-size: 36px
      font-weight: 500
      line-height: 46px
      margin: 50px auto

  &--subtitle
    margin: -85px auto 50px
    max-width: 800px
    font-size: 21px
    font-weight: 300
    line-height: 30px
    text-align: center
    color: $colors.text-mid

  &--floating-hint
    color: $colors.green-light
    margin-top: 55px
    font-size: 21px
    font-weight: bold
    display: flex
    flex-direction: column
    align-items: center

    &-arrow
      position: relative
      left: -110px
      margin-top: 5px

  &--choice-blocks
    display: flex
    justify-content: center

  &--logogrid
    &-wrapper
      position: relative
      min-height: 800px
      margin-top: 80px

    &-image
      display: block
      position: relative
      margin: 10px auto 55px
      width: 732px
      height: 503px

    &-grid
      z-index: 0
      pointer-events: none
      position: absolute
      top: -30px
      bottom: 30px
      left: 0
      right: 0

    &-elem
      &__python
        position: absolute
        left: 17%
        top: 0

      &__ruby
        position: absolute
        left: 25%
        top: 63%

      &__aws
        position: absolute
        left: 3%
        top: 50%

      &__dotnet
        position: absolute
        left: 80%
        top: 30%

      &__javascript
        position: absolute
        left: 16%
        top: 90%

      &__metalsmith
        position: absolute
        left: 82%
        top: 0

      &__swift
        position: absolute
        left: 93%
        top: 95%

      &__php
        position: absolute
        left: 96%
        top: 28%

      &__android
        position: absolute
        left: 43%
        top: 91%

      &__jekyll
        position: absolute
        left: 84%
        top: 55%

      &__gitbook
        position: absolute
        left: 0
        top: 23%

      &__brunch
        position: absolute
        left: 70%
        top: 75%


  &--choice-block
    position: relative
    text-align: center
    padding: 15px 25px
    border-radius: 4px
    width: 481px
    height: 240px
    margin: 0 35px
    border: 1px solid $colors.element-mid

  &--choice-block-title
    font-size: 21px
    font-weight: 600
    line-height: 29px

  &--choice-block-description
    font-size: 18px
    line-height: 25px
    color: $colors.text-mid

  &--block-button-wrapper
    position: absolute
    left: 0
    bottom: 38px
    width: 100%

  &--next-button
    display: block
    margin: 20px auto
    padding: 10px 55px

    &__left
      margin-left: 0
      margin-right: 0

  &--skip
    font-weight: 600
    font-size: 18px
    cursor: pointer
    color: $colors.text-mid


  &--navigation
    position: relative
    margin: 0 auto
    padding-bottom: 40px
    max-width: 800px

    &-wrapper
      display: flex
      justify-content: space-between
      position: relative
      z-index: 112

    &-circle
      color: $colors.blue-dark
      border: 3px solid $colors.blue-dark
      background: $colors.element-lightest
      font-weight: 700
      border-radius: 100%
      display: flex
      align-items: center
      justify-content: center
      width: 35px
      height: 35px

      &__active
        color: #fff
        background: $colors.blue-dark
        cursor: pointer

    &-line
      width: 100%
      height: 3px
      background: $colors.blue-dark
      position: absolute
      top: 16px

    &-block
      position: relative

    &-title-wrapper
      position: absolute
      white-space: nowrap

    &-title
      margin-top: 10px
      font-size: 11px
      font-weight: 600
      line-height: 15px
      color: $colors.blue-dark
      position: relative
      left: -35%

  &--copyscreen-content
    margin: 0 auto
    max-width: 1050px
    border-top: 2px solid #979797
    padding-right: 250px

  &--copyscreen-text
    font-size: 18px
    font-weight: 600
    line-height: 30px
    margin-top: 0
    padding-top: 50px
    color: $colors.text-mid

  &--copyscreen-snippet
    margin-top: 1.5rem

  &--copy-title
    font-size: 22px
    margin-top: 40px
    font-weight: 300

  &--content-explorer
    display: flex
    justify-content: space-between

    &-tabs
      padding-left: 50px
      margin-top: -40px

    &-data
      background: #fff
      width: 50%
      padding: 16px 50px 60px

    &-iframe
      width: 50%
      background-color: #536171
      padding: 75px 38px 30px

    &-wrapper
      background: #fff
      padding: 30px 20px
      height: 1144px
      overflow-y: auto

  &--iframe
    width: 100%
    height: 1000px
    border: 0
    display: block

  &--deploy-content
    margin: 0 auto
    max-width: 900px

  &--deployment-strategy
    border-top: 2px solid $colors.gray
    padding-top: 50px

    &-title-container
      display: flex
      align-items: center
      justify-content: space-between
      border-bottom: 1px solid $colors.element-mid
      padding-bottom: 15px
      margin-bottom: 50px

    &-expand-text
      color: $colors.text-mid
      font-size: 15px
      cursor: pointer

    &-expand-icon
      height: 0
      display: inline-block
      margin-left: 20px
      font-size: 30px
      position: relative
      top: 5px
      opacity: 0.85

    &-title
      font-size: 18px
      font-weight: 600
      line-height: 30px
      margin-top: 0

    &-subtitle
      font-size: 16px
      font-weight: 100

  &--deployment-list
    padding-left: 20px
    margin-top: 30px

    &-elem
      list-style: decimal
      padding-left: 10px
      margin-bottom: 40px

    &-text
      font-size: 18px
      font-weight: 600
      margin-top: 40px

  &--deployment-form
    max-width: 900px
    margin: 50px auto 30px

    &__centered
      text-align: center

    &-title
      font-size: 20px
      line-height: 25px
      font-weight: 600
      margin-bottom: 30px

    &-subtitle
      color: $colors.text-mid
      margin-top: -20px
      margin-bottom: 25px

    &-button
      padding: 14px 45px

  &--modal
    position: fixed
    z-index: 112
    top: 0
    bottom: 0
    left: 0
    right: 0
    background-color: rgba(17,17,17,0.6)
    display: flex
    justify-content: center
    align-items: center

    &-content
      background: #fff
      max-width: 1100px
      padding: 0 120px 50px
      text-align: center

  &--data-flow

    &-container
      position: relative
      height: 1000px

    &-diagram
      position: relative
      left: 15px
      top: 18px

    &-elem
      overflow: hidden
      width: 180px
      position: absolute
      border: 1px solid $colors.element-mid
      border-radius: 4px
      box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1)

      &-title
        height: 32px
        display: flex
        align-items: center
        justify-content: center
        font-size: 12px
        font-weight: 600
        line-height: 16px

        &__red
          background-color: $colors.coral-mid

        &__green
          background-color: $colors.green-lightest

        &__blue
          // TODO: find a color in our palette
          background-color: #e9f7ff

      &-subtitle
        border-top: 1px solid $colors.element-mid
        background-color: $colors.element-lightest
        height: 22px
        display: flex
        align-items: center
        justify-content: center
        font-size: 12px
        line-height: 16px

    &-line
      background-color: $colors.element-mid
      position: absolute

  &--code-title
    margin: 24px 0 16px
    font-size: 16px
    font-weight: 600
    line-height: 22px

  &--code-subtitle
    margin-top: -10px
    font-size: 14px
    line-height: 19px
    color: $colors.mid-text

  &--code-wrapper
    margin-top: 1.5rem
    position: relative

  &--code-block
    margin: 0

  &--active-data
    position: absolute
    top: -10px
    bottom: -10px
    left: -10px
    right: -10px
    border-radius: 4px
    transition: background 0.2s
    background: $colors.green-light
    opacity: 0.3
    pointer-events: none

  &--relaunch
    &-wrapper
      display: flex
      align-items: center
      justify-content: center
      height: 100%
      padding: 0 40px
      color: $colors.white

      &:hover
        cursor: pointer

        path, circle
          stroke: $colors.blue-mid

    &-icon
      margin-right: 10px

      @media (max-width: $nav-bar-break-width)
        display: none

      path, circle
        transition: all 0.1s ease-in-out
