.app-notification--anchor
  position: absolute
  left: 50%
  transform: translateX(-50%)
  z-index: 15
  min-width: 28em
  max-width: 40em
  cursor: pointer
  color: $colors.white
  z-index: 7

.app-notification
  display: flex
  align-items: baseline
  line-height: 1.5
  padding: 1em 2em
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out
  transform: translateY(0)
  background: $colors.green
  border: 1px solid $colors.green-darkest
  border-top: none

  &__dismiss
    flex: 0 0 auto
    margin-left: 1.5em
    font-weight: bold

  &.x--error
    background: $colors.red
    border: 1px solid $colors.red-darkest

  &.ng-hide-add
    transition: none

  &.ng-hide
    display: flex !important
    transform: translateY(-10%)
    opacity: 0

  > p
    flex: 1 1 auto
    margin: 0 1em 0 0
