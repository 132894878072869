.inline-reference-editor
  // Compensate for 80% width of outer .entity-editor__control
  width: 125%

  .loading-box
    width: 80%

  .bulk-editor-entry__fields
    padding-left: 1em
    padding-right: 0
  .bulk-editor-entry__header
    width: 80%

  .workbench
    position: relative
  .workbench-main__content
    border: none;

.bulk-editor-entry
  &__header
    display: flex
    align-items: center
    transition: background-color 0.2s linear
    background: $colors.element-lightest

    &.x--collapsed
      border-bottom: 1px solid $colors.element-dark

  &__header-main
    display: flex
    cursor: pointer
    flex-grow: 1

  &__header-expander
    font-family: 'FontAwesome'
    color: $colors.text-light
    font-size: 16px
    margin-left: 0.6em
    margin-top: 2px
    align-self: center

    transition: transform 0.1s linear
    &.x--collapsed
      transform: rotate(-90deg)
    &:before
      content: "\f0d7"

  &__header-title
    font-size: 18px
    padding-top: $new-rhythm(0.7)
    padding-bottom: $new-rhythm(0.7)
    font-weight: bold
    margin-left: 1.3em

  &__actions
    margin-left: auto
    padding: 1em 0
    margin-right: 1.5rem

  &__status
    margin-left: auto
    padding: 1em 0
    margin-right: 1.5rem
    text-transform: uppercase;
    font-weight: 600;

    +$spinner-base()
      margin-top: -1px
      display: inline-block
      margin-left: 5px

    &[data-state="archived"]
      color: $colors.red-light
      &[aria-busy="true"]
        $spinner-active($colors.red-light)
    &[data-state="draft"]
      color: $colors.orange-light
      &[aria-busy="true"]
        $spinner-active($colors.orange-light)
    &[data-state="published"]
      color: $colors.green-light
      &[aria-busy="true"]
        $spinner-active($colors.green-light)
    &[data-state="changes"]
      color: $colors.blue-light
      &[aria-busy="true"]
        $spinner-active($colors.blue-light)

  &__actions, &__status
    // Safari needs more space to render icon:
    padding-right: 4px

  &__fields
    padding: $new-rhythm(1) 3rem 0
