$note-box--common()
  clear: both
  overflow: hidden
  position: relative
  padding: 12px 12px 12px 42px
  color: $colors.text-mid
  line-height: 1.5
  > *:first-child
    padding-top: 0
    margin-top: 0
  > p:last-of-type
    margin-bottom: 0
    padding-bottom: 0
  > h3
    color: $colors.text-dark
    font-size: 14px
    line-height: inherit
    text-transform: none
    margin: 0
  a
    text-decoration: underline
    cursor: pointer
  &:before
    position: absolute
    content: ''
    background-repeat: no-repeat
    height: 18px
    width: 18px
    left: 12px
    top: 13px

// TODO deprecated
.note-box
  $note-box--common()
  &.note-box--info,
  &.note-box--success,
  &.note-box--warning
    // Old usage expects margin
    margin: $rhythm(1)

$note-box--success()
  $note-box--common()
  background-color: $colors.mint-mid
  border: 1px solid $colors.mint-dark
  a
    color: $colors.green-dark
  &:before
    background-image: url("/app/svg/note-success.svg")

$note-box--info()
  $note-box--common()
  background-color: $colors.ice-mid
  border: 1px solid $colors.ice-dark
  &:before
    background-image: url("/app/svg/note-info.svg")

$note-box--warning()
  $note-box--common()
  background-color: $colors.coral-mid
  border: 1px solid $colors.coral-dark
  a
    color: $colors.red-dark
  &:before
    background-image: url("/app/svg/note-warning.svg")

.note-box--info
  $note-box--info()

.note-box--success
  $note-box--success()

.note-box--warning
  $note-box--warning()
