.entity-link
  $element-color = $colors.element-mid
  $element-background = alpha(white, 0.95)

  > *
    padding-top: 0.85em
    padding-bottom: 0.85em

  display: flex
  position: relative
  border: 1px solid $element-color
  border-radius: 1px
  background-color: $element-background
  box-shadow: 0 1.5px 1.5px $colors.element-light

  &__action
    flex-shrink: 0
    align-self: flex-start
    padding-top: 1em
    padding-left: 0.3em
    padding-right: 0.3em
    cursor: pointer
    color: $colors.text-light
    &:last-child
      margin-right: 0.4em
    &:link
      color: $colors.text-light
    &:hover
      color: $colors.text-mid

  &__content
    flex: 1 1 auto
    overflow: hidden
    display: flex
    // Filled by drag handle
    margin-left: 30px
    a&
      $link-reset()

  &__image
    width: 4em
    height: @width
    flex: 0 0 @width;
    margin-right: 1em
    &--missing
      position: relative
      border: 1px solid
      border-color: $element-color
      overflow: hidden
      &:after,
      &:before
        content: ""
        position: absolute
        left: 50%
        top: -50%
        height: 200%
        width: 1px
        background-color: @border-color
        transform: rotate(45deg)
      &:after
        transform: rotate(-45deg)

// ReferenceCard Beta Start

.ReferenceCard {
  border: 1px solid $colors.element-mid;
  border-radius: 2px;
  display: flex;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  background: $colors.white;
  transition: all 200ms ease-in-out;
  transform: translate3d(0 ,0 ,0);
  cursor: pointer;

  &:hover {
    transform: translate3d(0 ,-2px ,0);
    box-shadow: 0 6px 3px -3px rgba(0,0,0,0.1);
    border-color: $colors.element-dark;
  }

  &--minimized {
    &:hover {
      transform: none;
    }
  }

  .entity-selector__item.x--selected & {
    background: $colors.element-light;
  }
}

.ui-sortable-helper .ReferenceCard {
  box-shadow: 0 3px 10px rgba(0,0,0,0.20);
}

.ReferenceCard__handle {
  width: 14px;
  border-right: 1px solid $colors.element-mid;
  align-items: center;
  display: flex;
  background: $colors.element-lightest;

  [data-drag-handle] {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    transition: background 200ms ease-in-out;

    &:hover {
      background: $colors.element-light;
    }
  }
}

.ReferenceCard__wrapper {
  flex: 1 0 0;
  padding: 14px;
}

.ReferenceCard__header {
  line-height: 21px;
  display: flex;

  /**
   * ReferenceCard actions are absolutely positioned. This padding is required
   * to make sure that content within the header don't cause any overlap.
   */

  &--has-actions {
    padding-right: 44px;
  }
}

.ReferenceCard__content-type {
  flex: 1 1 0;
  overflow: hidden;
  color: $colors.text-light;
  margin-bottom: 4px;
}

.ReferenceCard__status {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1em;

  &--archived  { color: $colors.red-light; }
  &--changed   { color: $colors.blue-light; }
  &--draft     { color: $colors.orange-light; }
  &--published { color: $colors.green-light; }
}

.ReferenceCard__actions {
  font-size: 12px;
  letter-spacing: 0.1em;
  color: $colors.text-mid;
  position: absolute;
  top: 14px;
  right: 14px;
}

.ReferenceCard__action {
  display: inline-block;

  svg {
    color: $colors.text-light;
    transition: color 200ms ease-in-out;
  }

  &:hover svg,
  &:focus svg {
    color: $colors.text-mid;
  }

  & + .ReferenceCard__action {
    margin-left: 4px;
  }
}

.ReferenceCard__content {
  line-height: 21px;
  position: relative;
  display: flex;
}

.ReferenceCard__text {
  flex: 1 0 0;
}

.ReferenceCard__title {
  font-weight: bold;
  color: $colors.text-dark;

  .ReferenceCard--minimized &,
  .ReferenceCard--missing & {
    flex: 1 0 0;
  }
}

.ReferenceCard__description {
  margin-top: 4px;
  color: $colors.text-mid;
}

.ReferenceCard__image {
  img {
    display: block;
  }

  &--thumbnail {
    margin-left: 20px;
  }
}
