$focus-styles()
  outline: 1px dotted
  outline: -webkit-focus-ring-color auto 5px

$tooltip-opaque()
  .tooltip.in
    opacity: 1

$caret()
  font-family: 'FontAwesome'
  color: white
  font-size: $caret-size
  position: absolute

$scroll-vertically($height)
  overflow-y: auto
  max-height: $height

// TODO would be good to see this used more as a class but nesting
$tr-clickable()
  &:hover
    background-color: $result-list-highlight
    cursor: pointer

// Main styles
$result-list-cell()
  line-height: 18px
  vertical-align: middle
  padding: 0px
  padding-right: 10px
  text-align: left
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  span
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
