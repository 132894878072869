// Layout fixes for iframe
.tab-content.tab-iframe
  top: 0
  bottom: 0
  left: 0
  right: 0
  border: 0

  .header-separator
    color: $gray-light

.spaces .settings-panel
  margin-top: 0
  padding-top: $tab-main-margin-top
  padding-left: 25px
  background-color: #fdfcfc
  border-right: 1px solid $gray-lightest
  a
    color: #8d8c8c
  .active a
    color: #434343
    font-weight: bold
  ul
    margin-top: 0
  li
    font-size: 14px
    line-height: 25px

.account .settings-panel
  padding: 0
  color: black
  font-weight: bold
  border-right: 1px solid $gray-light
  ul
    padding: 10px 0
    border-bottom: 1px solid $gray-light
  li
    padding-top: 8px
    padding-bottom: 8px
    &.active a
      color: $blue
  a
    color: black
    &:hover
      color: $blue
  h3,
  li
    padding-left: 40px
  h3
    font-size: 16px
    font-weight: bold
    $gradient(lighten($gray-lightest, 4%), $gray-lightest)
    line-height: 40px
    position: relative
    border-bottom: 1px solid $gray-light
    margin: 0
  img
    height: 15px
    position: absolute
    top: 13px
    left: 15px
  .fa-users
    position: absolute
    left: 15px
    top: 12px
    font-size: 14px

// Adjust base font size // YOLO
.account
  .tab-iframe
    font-size: 14px

// Adjust content padding
.tab-iframe
  .button_to
    display: inline
    div
      display: inline

  // Main tables
  .result-list
    border-left: none
    border-right: none
    border-top: none
    td
      padding: 8px 0px
    thead
      text-transform: uppercase
      letter-spacing: 1px
    .clickable
      tr
        $tr-clickable()
    tbody
      tr:last-child
        border-bottom: 1px solid $gray-lightest
  .result-list-thumbnail
    vertical-align: middle

