@require "nib/vendor"

*
  box-sizing(border-box)

$background()
  background-color: $colors.element-mid

body
  $sans-font()
  text-rendering: optimizeLegibility
  $establish-baseline()
  color: $base-font-color
  overflow-x: auto
  overflow-y: hidden

button,
input[type="submit"]
  line-height: inherit
  font-family: inherit

button::-moz-focus-inner
  border: 0
  padding: 0

table
  border-collapse: separate

a
  color: $blue-link
  text-decoration: none
  &:link
    color: $blue-link
    text-decoration: none
  &:hover
    color: $blue-dark-link

i
  font-style: normal

h1, h2, h3, h4, h5, h6
  color: $colors.text-dark
  line-height: 1em
  font-weight: bold
  margin: 18px 0 8px

h1
  font-size: 2.1em
  margin-bottom: .3em

h2
  font-size: 1.5em

h3
  font-size: 1.2em

hr
  height: 1px
  background-color: $gray-lightest
  margin: 30px 0
  border: none

li
  list-style-type: none
  margin-bottom: 5px

p,
blockquote
  margin-bottom: 10px

ul,
ol
  margin: 0
  padding: 0

blockquote
  border-left: 4px solid $gray-lightest
  padding-left: 20px
  margin-top: 10px
  font-style: italic

b, strong
  color: $colors.dark-gray
  font-weight: bold
