.api-cm-list
  &:first-child
    margin-top: $rhythm(-1)
  &__item
    border-bottom: 1px solid $colors.element-light
    &__toggle
      display: flex
      cursor: pointer
      padding: 12px 0
      cf-icon[name="dd-arrow-down"]
        transition: transform 0.2s ease-in-out
        margin-left: 8px
        margin-top: 4px
        margin-bottom: 8px
        path
          fill: $blue-link
      &:hover
        cf-icon[name="dd-arrow-down"]
          path
            fill: $blue-dark-link
    &.x--open
      background-color: white
      cf-icon[name="dd-arrow-down"]
        transform: rotate(-180deg)
  .table
    .tooltip
      white-space: normal
    i.fa-question-circle
      margin-left: 8px
      color: $colors.text-mid


.content-model
  &__summary
    display: flex
    align-items: center
    justify-content: space-between
    padding: 20px 25px 19px
  &__info
    flex: 2
  &__id
    flex: 1
    margin: 0 15px
  &__id__title
    font-size: 12px
    color: $colors.text-light
    margin-bottom: 8px
  &__name
    margin: 8px 0
  &__description
    color: $colors.text-light
