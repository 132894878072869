.snapshot-list
  table
    margin-bottom: 1em
  td
    vertical-align: middle
    font-size: 0.85em
    padding: 5px 7px

.snapshot-selector
  width: 40%
  min-width: 600px
  max-width: 700px
  border: 1px solid $colors.element-light
  box-shadow: 0 1px 2px 0 rgba(black, 0.1)
  position: absolute
  top: 122px
  left: 10px
  padding: 25px
  background-color: white
  $layer(dropdown)

  &:after
    content: ''
    display: block
    position: absolute
    top: -6px
    left: 86px
    height: 8px
    width: 8px
    background-color: white
    transform: rotate(45deg)
    border: 1px solid $colors.element-light
    border-bottom-color: transparent
    border-right-color: transparent


  &__list
    > .result-list.main-header tr
      background-color: $colors.element-lightest

      th
        text-transform: none
        font-size: 100%
        letter-spacing: normal
        padding-top: 10px
        padding-bottom: 10px
        padding-left: 10px
        line-height: 1.5em
        i
          margin-left: 5px
  &__item
    &-status
      font-size: 0.9em
      letter-spacing: 2px
      text-transform: uppercase
    &-status-published
      color: $colors.green-dark
    &-status-draft
      color: $colors.blue-dark
    &-list-placeholder
      color: $colors.text-mid
      padding-left: 10px
      padding-top: 20px
  .main-results-wrapper
    max-height: 45vh
    overflow-y: scroll

    tr
      color: $colors.text-mid
      &:hover
        cursor: pointer
        background-color: $colors.element-lightest
      td
        border-bottom: 1px solid $colors.element-light
        padding-top: 16px
        padding-bottom: 16px
        line-height: 1.5em
        padding-left: 10px

  .x--sortable
    &:hover
      cursor: pointer
      background-color: $colors.element-light
  .x--current
    background-color: $colors.ice-mid
  .x--selected-header
    font-weight: bold
    .button-icon
      color: $colors.text-light
      font-weight: normal

.snapshot-comparator__header
  display: flex
  background-color: $colors.element-lightest

.snapshot-comparator__cell,
.snapshot-comparator__header-cell
  display: flex
  flex-grow: 1
  flex-basis: 0


  &:first-child
    border-right: 1px solid #DBDBDB

    .x--selected > span
      display: inline-block

.snapshot-comparator__header-cell
  padding: 1.5em 4em
  justify-content: space-between
  align-items: center
  border-bottom: 1px solid $colors.element-darkest

  > label
    display: flex
    align-items: center
    > input
      margin: 0 7px 2px

.snapshot-comparator__version
  color: $base-font-color
  font-size: 16px
  font-weight: bold
  padding-right: 4px

  &.btn-link:hover
    transition: none
    color: $colors.gray
    cf-icon path
     fill: $colors.gray

  cf-icon path
    fill: $base-font-color

.snapshot-comparator__field-locale
  display: flex

.snapshot-comparator__cell
  position: relative
  border-bottom: 1px solid #EEEEEE

  &__radio
    min-width: 4em
    max-width: 4em
    display: flex
    justify-content: center
    align-items: center
  &__presenter
    flex-grow: 1
    padding: 4em
    padding-left: 0

.snapshot-comparator__field-title
  color: $colors.text-light
  margin-bottom: $rhythm(0.5)
  display: flex
  justify-content: space-between

.snapshot-comparator__presenter
  font-size: 1.1em

  &.x--dir-rtl
    .x--directed
      direction: rtl

  .symbol-array-preview li
    margin-left: 1.5em
    list-style-type: disc

.snapshot-comparator__overlay-different,
.snapshot-comparator__overlay-identical
  $layer(snapshotOverlay)
  background-color: rgba(0, 0, 0, 0)
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0

.snapshot-comparator__overlay-identical
  background: rgba(255, 255, 255, 0.75)

.snapshot-comparator__overlay-different
  cursor: pointer
  text-align: center

  > span
    display: none
    padding: 3px 8px
    font-size: 0.75rem
    font-weight: 500
    letter-spacing: 1px
    text-transform: uppercase
    background: #4A90E2
    color: #FFF

  &.x--selected
    $layer(snapshotOverlaySelected)
    border: 1px solid #4A90E2
    background-color: alpha($colors.blue-light, 0.05)
    top: -1px
    left: -1px
    right: -1px
    bottom: -1px

.snapshot-comparator__hint
  top: 120px
  left: 30px
  box-sizing: border-box
  padding: 15px

  .ui-hint-content
    display: inline-block
    max-width: 600px
    margin-bottom: 0

  [data-arrow-direction="up"]
    top: -7px

.snapshot-comparator__leave-dialog
  max-width: 600px
