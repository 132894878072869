$home-width = 820px

.home
  $stretch(0, 0, 0, 0)
  background-color: $colors.bg-light
  overflow-y: auto
  overflow-x: hidden

  &__container
    background-image: url('/app/images/home-bg.png')
    background-size: 1280px auto
    background-repeat: repeat-x

  &__content
    position: relative
    padding-top: $rhythm(1)
    width: $home-width
    margin: 0 auto

  &__welcome-image
    position: absolute
    bottom: 0
    right: 30px

  &-section
    width: $home-width
    position: relative
    background-color: white
    border: 1px solid $colors.element-mid
    box-shadow: 0px 1px 2px 0px hsla(black, 0.1)
    display: block
    margin: 0 auto 16px auto
    padding: 24px
    &__heading
      margin-top: 0
      line-height: 1.5em
    &__description
      color: $colors.text-mid
      line-height: 1.5em
      margin: 20px 0
    &__body
      button
        margin-right: 24px
    &.x--onboarding-steps
      padding: 0
      .home-section__heading
        padding: 24px 24px 0 24px
      .home-section__description
        padding: 0 24px
  &-links
    display: flex
    &__col
      width: 50%
      a
        display: block
        line-height: 2em

  &__examples
    display: flex

  &-example
    display: flex
    flex-direction: column
    justify-content: space-between
    width: 22%
    margin-right: 2%
    border: 1px solid $colors.element-mid
    box-shadow: 0px 1px 2px 0px hsla(black, 0.1)
    padding: 14px 10px
    &__header
      color: $colors.text-dark
      margin-top: 0
    &__description
      color: $colors.text-mid
    &__dot
      border: 1px solid $colors.element-mid
      height: 12px
      width: 12px
      float: left
      margin: 0 7px 10px 0
    &__link
      align-self: flex-end
      width: 100%
    &:nth-child(4n+1)
      .home-example__dot
        background-color: $colors.green-light
    &:nth-child(4n+2)
      .home-example__dot
        background-color: $colors.orange-light
    &:nth-child(4n+3)
      .home-example__dot
        background-color: $colors.blue-light
    &:nth-child(4n)
      .home-example__dot
        background-color: $colors.red-light

.onboarding-step
  padding: 24px
  border-top: 1px solid $colors.element-mid
  display: flex
  align-items: center
  &.x--disabled
    opacity: 0.6
  &__icon
    min-width: 40px
    max-width: 40px
  &__text
    flex-grow: 1
  &__heading
    margin-top: 0
  &__cta
    text-align: right
    min-width: 210px
    cf-icon
      margin-right: 4px
  &__completed
    font-weight: bold
    color: $colors.green-darkest
    display: flex
    justify-content: flex-end
  &__link
    margin-top: 6px
    a
      color: $colors.text-light
      text-decoration: underline

.tab-list.x--home
  border: 1px solid transparent
  border-bottom: 1px solid $colors.element-mid
  margin: 0 1px 16px 1px
  width: $home-width - 2px
  transition: background-color 0.2s ease-in-out
  z-index: 1
  &.fixed
    background-color: white
