.done-left-column
  float: left
  width: $form-labelwidth
  padding-left: 20px
  margin-top: 20px
.done-main-column
  margin-top: 20px
  float: left

.done-icon
  margin: auto
  background-color: $green
  width: 80px
  height: 80px
  border-radius: 40px
  position: relative
  i
    position: absolute
    top: 35px
    left: 19px
    color: white
    font-size: 46px
.done-plan-info
  margin: 1em 0
  font-size: 1.5em
  font-weight: bold
.done-buttons.formtastic
  clear: both
  margin-top: 6em

.billing-info-box,
.formtastic .billing-info-box
  border: 1px solid $gray-light
  border-style: solid none
  background-color: $subscription-yellow
  padding: 10px
  p
    margin: 10px

