@require './context_menu'

.delete-confirm
  $context-menu-base()
  width: 350px

  padding: 1.3rem
  text-align: center
  > h2,
  > p
    margin-bottom: 20px

  > button:first-of-type
    margin-right: 1ex
