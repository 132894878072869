.entity-list__item
  $tr-clickable()

.entity-list__item.x--with-icon
  display: flex
  align-items: center
  border-bottom: 1px solid $colors.element-light
  padding: 20px 25px 19px
  &, &:link, &:hover, &:active
    color: $colors.text-light
  &:hover
    background-color: $colors.element-lightest
  cf-icon[name="dd-arrow-down"]
    margin-left: auto
    transform: rotate(-90deg)
    path
      fill: $colors.text-lightest
  &__heading
    margin-top: 0


/* Cleanup notes
some stuff here should go into components, other into layout
*/
.entity-list
  .add-entity__advice
    margin-bottom: 0

  .main-results-wrapper
    $stretch(30px, 0, 0, 0)
    margin-top: $rhythm(1)
    overflow-y: auto
    .main-results
      border-left: none
  .entity-status
    text-transform: uppercase
    letter-spacing: 2px
    font-size: 0.9em

  .result-list
    tbody tr
      $tr-clickable()

  &__heading
    margin: 8px 0
  &__description
    display: block
    margin: 8px 0
