@require '../variables/colors'

.create-new-space-dialog
  &.modal-dialog
    width: 750px
    text-align: center
  &__header
    background-color: white
    border: 0
    padding: 0
  &__heading
    line-height: 1.2em
    margin: 0 0 8px
  &__subheading
    font-size: 1.05em
    line-height: 1.3em
    color: $colors.text-mid
  &__controls--right
    justify-content: flex-end
  &__controls--centered
    justify-content: center

.create-new-space__form
  text-align: left
  padding: 20px 12px 0 12px
  &__option
    margin-bottom: 10px
  &__label-description
    color: $colors.text-mid
  &__radios
    margin-bottom: 20px

.create-new-space__fieldset
  display: flex
  > .cfnext-form__field
    margin-right: 30px
    width: 50%
    &:last-child
      margin-right: 0
    > select, > input
      width: 100%

.create-new-space__templates
  position: relative
  text-align: left
  transition: height 0.2s ease-in-out
  overflow: hidden
  // height: 101px
  padding: 0
  &.close
    height: 0
  &.open
    overflow: hidden
    // height: 329px
  &__inner
    padding: 30px 42px
    margin-top: 11px
    background-color: $colors.element-lightest
    border-top: 1px solid $colors.element-mid
    border-bottom: 1px solid $colors.element-mid

  cf-icon[name="arrow-up"]
    position: absolute
    left: 38px
  path
    fill: $colors.element-lightest

  &__nav
    display: flex
    border-left: 1px solid $colors.element-mid

  &__navitem
    cursor: pointer
    display: flex
    color: $colors.text-light
    font-weight: bold
    border: 1px solid $colors.element-mid
    border-left-width: 0
    padding: 8px
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out
    path
      fill: $colors.text-light
    &.selected
      color: $colors.text-dark
      border-bottom-width: 0
      background-color: white
      path
        fill: $colors.green-mid
    &__icon
      padding-right: 5px
    &:hover:not(.selected)
      background-color: $colors.element-light
      color: $colors.text-mid

  &__description
    overflow: auto
    display: flex
    background-color: white
    padding: 20px
    border: 1px solid $colors.element-mid
    margin-top: -1px
    box-shadow: 0 1px 3px rgba(0,0,0,0.08)

  &__image
    width: 220px
    min-width: 220px
    max-width: 220px
    height: 160px
    border: 1px solid $colors.element-mid
    margin-right: 20px
  &__text
    display: flex
    align-items: center
    color: $colors.text-mid
  &__entities
    margin: 20px 0
  &__entity
    display: flex
    text-align: left
    margin-top: 30px
    padding: 0 60px
    &__description
      margin-left: 20px
      h3
        margin: 0 0 10px 0
  &__status
    height: 27px
    width: 27px
    margin: 6px auto
    .spinner
      $spinner-base()
      $spinner-active($colors.text-dark, $base-line-height*1.5)
      &:after
        display: block

// Close button

$close-button-small()
  transform: none;
  color: $colors.text-light
  &:hover
    color: $colors.text-mid
  &:after
    font-size: 2em
    line-height: 1em

.create-new-space-dialog__close, .create-space-wizard__close
  &.modal-dialog__close
    $close-button-small()
    position: absolute
    top: 14px
    right: 18px

// V2 pricing wizard
.create-space-wizard
  &__heading
    line-height: 1.3em
    text-align: center
  &__subheading
    line-height: 1.5em
    color: $colors.text-mid
    text-align: center
    margin-bottom: 28px
    font-size: 1.1em
  &__info
    line-height: 1.5em
    margin-bottom: 30px
    color: $colors.text-mid
  &__step
    display: none
    &--current
      display: block
  &__navigation
    $tab-list()
    width: 100%
    justify-content: center
    [role=tab][aria-disabled="true"]
      opacity: .3
      cursor: not-allowed
  &__centered-block
    width: 420px
    margin: 0 auto

  & > .modal-dialog__content
    padding: 20px 60px 40px

  & .fieldset
    border: 1px solid $colors.element-mid
    padding: 0 10px
    margin: 20px 0 0
    border-radius: 2px

    & > .field
      margin: 10px 0 15px

  // Temporary, until the DatePicker is in ui-component-library
  & .label
    display: inline-block
    color: $colors.text-dark
    font-weight: 600
    margin-bottom: 0.5rem

.space-plans-list
  &__item__heading
    font-size: 17px
    line-height: 1.2

    .help-icon__img svg path
      fill: $colors.element-mid

  $item-label($color, $border-color = null)
    &:before
      background-color: $color
      border: 1px solid $border-color

  $item-tag($text, $color, $text-color)
    &:after
      content: $text
      color: $text-color
      background-color: $color
      border-radius: 2px
      position: absolute
      right: -20px
      top: 35%
      padding: 6px 10px
      font-family: "Avenir Next"
      text-transform: uppercase
      font-weight: 600
      font-size: 12px
      line-height: 12px
      letter-spacing: 0.4px
      opacity: 0.9

  display: flex
  flex-direction: column
  &__item
    height: 73px
    border-radius: 2px
    border: 1px solid $colors.element-mid
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.08)
    background-color: #fff
    margin-bottom: 10px
    padding: 14px 30px
    line-height: 1.5em
    display: flex
    flex-direction: column
    position: relative
    cursor: pointer
    transition: all ease-in-out .1s
    $item-label($colors.text-lightest, $colors.text-light)
    &:hover
      z-index: 1
    &:not(&--disabled, &--proof-of-concept):hover
      border: 1px solid $colors.element-darkest
      box-shadow: 0 3px 3px 0 rgba(0,0,0,0.08)
      transform: translateY(-2px)
    &--selected
      border: 1px solid $colors.blue-dark
    &:before
      content: ''
      width: 8px
      height: 100%
      position: absolute
      top: -1px
      left: -1px
      border-radius: 2px 0 0 2px;
    &--free
      $item-label($colors.element-light, $colors.element-mid)
    &--micro
      $item-label($colors.orange-light, $colors.orange-mid)
    &--small
      $item-label($colors.red-light, $colors.red-mid)
    &--medium
      $item-label($colors.blue-light, $colors.blue-mid)
    &--large
      $item-label($colors.green-light, $colors.green-mid)
    &--partner-space-plan
      $item-label($colors.blue-dark, $colors.blue-darkest)
    &--proof-of-concept
      $item-label($colors.blue-dark, $colors.blue-darkest)
      &:hover
        cursor: default
        z-index: 2
    &--disabled
      $opacity = 0.3
      cursor: not-allowed
      border-color: 1px solid rgba($colors.element-mid, $opacity)
      &, & em, & strong
        color: rgba($colors.text-dark, $opacity)
      &:before
        opacity: $opacity
    &--current
      $item-tag("Current", #a9b9c0, #fff)
    &--recommended
      $item-tag("Recommended", #5095ed, #fff)

    &__heading
      font-size: 17px
      line-height: 1.2

    &__features
      display: flex
      flex-direction: row
      margin-top: 4px
      & > li
        margin: 0 10px
        &:first-child
          margin-left: 0
        &:last-child
          margin-right: 0
    &:not(&--disabled) &__features
      color: $colors.text-mid
    &__chevron
      transform: rotate(-90deg)
      position: absolute
      right: 30px
      top: 50%
      height: 0
      color: $colors.element-dark

