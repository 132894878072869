.user-role-selector
  display: block;
  border: 1px solid $colors.ice-dark
  background-color: $colors.element-lightest
  max-height: 235px
  overflow: auto
  padding: 12.5px 0
  margin-bottom: $rhythm(1)

  // Hide 'unconfirmed' message from cf-user-link
  .user-link__unconfirmed
    display: none

  &__user
    display: flex
    align-items: center
    justify-content: space-between
    padding-right: 25px
    margin: 0

  &__selector-field
    > select
      max-width: 200px
