.header
  display: grid
  grid-template-columns: 1fr 1fr
  grid-gap: 45px

  & > div
    margin: 1em 0 3em

.platform
  grid-column: 1
  grid-row: 1

.users
  grid-column: 2
  grid-row: 1

.spaces-table
	th
   padding-right: 10px

  tbody td
    vertical-align: text-top
