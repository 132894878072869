.role-list, .user-list
  .context-menu.x--arrow-up
    &:before, &:after
      left: 92%

.user-list__actions,
.role-list__actions
  min-width: 25px
  font-size: 0.6em
  letter-spacing: 0.18em
  &--disabled
    color: $colors.text-lightest
    cursor: default

/**
 * Role list
 */

.role-list__item
  display: flex
  align-items: center
  justify-content: space-between
  border-bottom: 1px solid $colors.element-light
  padding: 20px 25px 19px
  h3
    margin-top: 0
  p
    margin-bottom: 0
  &:first-child
    margin-top: -19px

  &.disabled
    background: $colors.element-lightest
    user-select: none
    cursor: default

    > .role-list__member-count
      color: $colors.text-lightest

.role-list__link
  width: 70%
  h3
    color: $colors.text-dark
  p
    color: $colors.text-lightest
  &.admin-role
    cursor: default
  &:not(.admin-role)
    cursor: pointer
  &:not(.admin-role):hover > *
    color: $colors.blue-mid

.role-list__action--disabled
  color: $colors.text-lightest
  cursor: default
  &:hover
    background: transparent
    color: $colors.text-lightest

.admin-role
  h3 small
    display: inline-block
    padding-left: 10px
    font-size: .7em
    font-weight: normal
    color: $colors.text-lightest

.role-details
  padding: 10px 0

.role-details__section
  padding: 25px
  padding-left: 45px
  position: relative
  border-top: 1px solid $colors.element-light
  em
    color: $colors.text-light
  h3
    position: absolute
    top: -27px
    left: 15px
    background: white
    padding: 0 10px

/**
 * Rule editor
 */

.rule-list__note
  margin: 10px 0

.rule-list__rule
  margin: 10px 0
  select
    margin-right: 10px
    margin-bottom: 5px

.rule-list__add
  display: inline-block
  margin: 0 0 30px

.rule-list__remove
  font-size: 1.3rem

/**
 * User list
 */

.user-list__group
  margin: 40px 0
  position: relative
  border-top: 1px solid $colors.element-light
  h3
    margin: 0
    display: inline-block
    position: absolute
    left: 15px
    top: -10px
    font-size: 1.4em
    background: white
    padding: 0 10px
    min-width: 40px

.user-list__item
  display: flex
  align-items: center
  justify-content: space-between
  margin-top: 15px
  padding: 5px 30px
  padding-left: 55px
  cursor: default

.user-list__info
  margin-left: 15px
  margin-right: auto
  overflow: hidden
  strong
    display: block
    margin: 3px 0 2px
  small
    font-size: .9em
    color: $colors.text-lightest
