.fade
  opacity: 0
  transition-property: opacity
  transition-duration: 0.15s
  transition-timing-function: linear

.fade.in
  opacity: 1

.tooltip
  position: absolute
  $layer('tooltip')
  display: block
  font-size: 12px
  line-height: 1.4
  opacity: 0
  visibility: visible

.tooltip.in
  opacity: 1

.tooltip.top
  padding: 5px 0
  margin-top: -3px

.tooltip.right
  padding: 0 5px
  margin-left: 3px

.tooltip.bottom
  padding: 5px 0
  margin-top: 3px

.tooltip.left
  padding: 0 5px
  margin-left: -3px

.tooltip-inner
  padding: 8px
  color: #ffffff
  text-align: center
  text-decoration: none
  background-color: #192532
  border-radius: 4px

.tooltip-arrow
  position: absolute
  width: 0
  height: 0
  border-color: transparent
  border-style: solid

.tooltip.top .tooltip-arrow
  bottom: 0
  left: 50%
  margin-left: -5px
  border-top-color: #192532
  border-width: 5px 5px 0

.tooltip.right .tooltip-arrow
  top: 50%
  left: 0
  margin-top: -5px
  border-right-color: #192532
  border-width: 5px 5px 5px 0

.tooltip.left .tooltip-arrow
  top: 50%
  right: 0
  margin-top: -5px
  border-left-color: #192532
  border-width: 5px 0 5px 5px

.tooltip.bottom .tooltip-arrow
  top: 0
  left: 50%
  margin-left: -5px
  border-bottom-color: #192532
  border-width: 0 5px 5px

// workaround version to use tooltips without a 3rd party jQuery plugin
// TODO: find a way to use tooltips with React components and remove the styles below
.tooltip.hidden
  display: none

.tooltip-trigger
  position: relative
  cursor: help
  &:hover
    > .tooltip
      display: block
      opacity: 1
