.persistent-notification
  display: flex
  align-items: center
  width: 100%
  // FIXME: this should notbe necessary. See comment in app_container.styl
  height: 45px
  padding: 8px
  background-color: $colors.yellow
  &__message
    margin-right: 10px
  &__action
    margin-bottom: 0px
    padding: 0.4rem 1rem 0.3rem
    &:active
      padding: 0.5rem 1rem 0.2rem
  &__dismiss
    color: $colors.dark-gray
    font-weight: bold
    margin-left: auto
    padding-left: 8px
