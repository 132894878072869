.view-menu
  display: flex
  flex-direction: column
  height: calc(100vh - 210px)

.view-menu__folders
  flex: 1 0 200px
  overflow-y: auto 
  padding-top: 10px 

.view-menu__actions
  flex: 0 1 75px
  height: 75px
  max-height: 75px;
  background-color: $colors.element-lightest

  button
    margin-left: 1.5em
    margin-bottom: 10px
    i
      display: inline-block
      margin-right: 5px

.view-menu__empty
  display: flex
  flex-direction: column
  height: 100%

.view-menu__empty-message
  display: flex
  flex-direction: column
  height: 100%

.view-menu__empty-cta
  flex: 1
  position: relative

  button
    position: absolute
    bottom: 5px
    padding-left: 1.5rem
  

.view-menu .sortable-ghost
  background-color: $colors.element-lightest
  opacity: .6
  border: 1px dashed $colors.element-mid

.view-folder
  &:first-child
    .view-folder__header
      border-top: 0

  i
    display: inline-block
    margin-left: 5px
    cursor: pointer
    color: $colors.text-lightest
    &:hover
      color: $colors.text-mid

  .view-folder__actions
    display: none
  .view-folder__header:hover .view-folder__actions,
  .view-folder__item:hover .view-folder__actions
    margin-left: 5px
    display: inline-block


.view-folder__empty
  color: $colors.text-light
  padding: 1.5rem

.view-folder__header
  display: flex
  justify-content: space-between
  align-items: start
  color: $colors.text-dark
  border-top: 1px solid $colors.element-light
  font-size: 12px
  font-weight: bold
  padding: 0 1.5rem
  text-transform: uppercase
  letter-spacing: 1px

.view-folder__title,
.view-folder__toggle
  max-width: 85%
  padding: 20px 3px
  word-wrap: break-word


.view-folder__toggle
  transition: .15s transform ease-in-out

  &.-collapsed
    transform: rotate(90deg)
    transition: .15s transform ease-in-out

.view-folder.-draggable .view-folder__header
  cursor: move
  cursor: -webkit-grab

.view-folder__list
  margin-bottom: 10px

  // we need some minimal height so the list can accept dragged items
  min-height: 2px

  &.-collapsed
    margin-bottom: 0

.view-folder__item
  display: flex
  justify-content: space-between
  padding: 8px 1.5rem
  margin: 0
  border: 1px solid transparent
  cursor: pointer
  &:hover
    background-color: $colors.element-mid
  .view-folder__item-title
    color: $colors.text-dark
    width: 100%
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis


  &.-active
    border-radius: 3px
    background-color: $colors.element-light
    & .view-folder__item-title
      font-weight: bold

  .view-folder__actions
    width: 80px
    text-align: right

.view-folder__seperator-wrapper

.view-folder__seperator
  margin-bottom: 25px
  border-bottom: 1px solid $colors.element-dark
