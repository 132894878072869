@require 'variables/colors'
// Do not show a red shadow around invalid inputs in Firefox.
//
// We use the `required` attribute on inputs to add validations. This
// also triggers in-browser validations that show a red box around the
// input.
:-moz-ui-invalid
  box-shadow: none

$link-reset()
  &, &:link, &:hover, &:visited
    color: inherit
    text-decoration: none

h1, h2, h3, h4, h5, h6
  &.h-reset
    color: $colors.text-dark
    line-height: 1.5
    font-weight: bold
    margin: 0

.input-reset
  border: 0
  padding: 0
  font-family: inherit

.select-reset
  border: 0
  padding: 0
  font-family: inherit

