// Width of left-hand main content.
// We only show the field collaborators on the right
$entity-editor__field-width = 80%

.entity-editor__field-group
  display: block
  margin-top: 19px
  margin-bottom: 29px
  padding-left: 1em
  border-left: 3px solid #C5D2D8
  transition: border-color 0.18s linear
  &[aria-current="true"]
    border-left-color: $colors.blue-mid
  &[aria-invalid="true"]
    border-left-color: $colors.red-mid

  .x--dir-rtl
    .x--directed
      direction: rtl
    select.x--directed
      float: right

.entity-editor__field-locale
  margin-top: 28px
  &:first-child
    margin-top: 0


.entity-editor__field-heading
  display: flex
  font-size: 14px
  line-height: 24px
  color: $colors.text-light
  width: $entity-editor__field-width

  label
    display: flex
    white-space: pre-wrap
    width: 100%

    div.inline-toggle
      margin-left: auto
      cursor: pointer

      cf-icon
        display: inline-block
        position: relative
        right: 5px
        top: 5px

      span.banner
        color: $colors.blue-dark
        font-size: 0.9em
        letter-spacing: 2px
        text-transform: uppercase
        margin-left: 7px

.entity-editor__no-permission-info
  margin-left: auto
  white-space: nowrap
  > cf-icon
    display: inline-block
    margin-right: 0.5em
    path
      fill: $colors.text-light


.entity-editor__control-group
  display: flex
  justify-content: space-between

.entity-editor__control
  width: $entity-editor__field-width

.entity-editor__control-error
  list-style-type: disc
  margin-left: 1em
  font-weight: bold

  a:link
    color: $colors.red-dark
  a:hover, a:visited
    color: darken($colors.red-dark, 20%)

  i.fa.fa-external-link
    margin-left: 0.5em
    position: relative
    top: 1px

.entity-editor__field-hint
  width: $entity-editor__field-width
  color: $colors.text-lightest
  margin: $rhythm(0.5) 0
  font-style: italic


.entity-editor__field-collaborators
  margin: 0 0 0 2em
  margin-left: $form-offset
  display: flex
  flex-wrap: wrap
  > li
    $image-size = 30px
    overflow: hidden
    margin-right: 5px
    border-radius: 50%
    width: $image-size
    height: $image-size
    > img
      display: block
      height: $image-size
