@require '/variables/colors'

.rating-editor
  display: flex

.rating-editor__star
  padding-right: 0.5em
  margin-top: -2px

  cursor: pointer
  &[aria-disabled="true"]
    cursor: default

  svg path
    fill: $colors.element-light
  &[data-active="true"]
    svg path
      fill: $colors.blue-mid

.rating-editor__clear
  margin-left: 0.5em
