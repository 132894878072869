.editor-control-warning
  line-height: 1.5
  // TODO make the colors variables
  background: #fffde2
  border: 1px solid #F9E900
  padding: $new-rhythm(0.5) 1.4em

  display: flex
  align-items: center

  > cf-icon
    margin-right: 1.5em

  > cf-icon[name="close"] path
      fill: #F9E900

  p
    margin: 0
