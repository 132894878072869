$formtastic-fieldwidth = 40%
$form-table-width = $form-labelwidth + $formtastic-fieldwidth
.subscription-progress-bar
  width: 100%
  line-height: 40px
  margin: 0 0 20px 0
  padding: 0 20px
  border-bottom: 1px solid $gray-light
  text-transform: uppercase
  letter-spacing: 1px
  font-size: 14px
  $pie-clearfix()
  li
    display: block
    width: 25%
    float: left
    text-align: center
    margin: 0
  .fa-check
    color: $green
    padding-right: .5em
    position: relative
    top: 2px
  .active
    color: $colors.dark-gray
    font-weight: bold
    background-color: lighten($gray-lightest, 2%)

.tab-iframe .formtastic .input.yellow-highlight,
.formtastic .input.yellow-highlight,
.formtastic .yellow-highlight
  background-color: $subscription-yellow
  padding: 10px 0
  $scalefactor = 100% / ($form-table-width + 3%)
  label
    width: $form-labelwidth
  .inline-hints
    margin-left: $form-labelwidth
  p, ul
    margin: 10px 20px
  ul
    margin-left: 40px

.credit-card-secure
  color: $gray-medium

.form-table
  width: $form-table-width
  tr
    line-height: 35px
    height: 35px
  // scalefactor to make th width relative to total form
  th
    $scalefactor = 100% / $form-table-width
    width: $form-labelwidth * $scalefactor
    text-align: right
    padding-right: 12px
    font-weight: normal
  td
    font-weight: bold
    font-size: 115%
    padding-left: 12px

.formtastic.with-sidebar
  position: relative
.formtastic-sidebar
  position: absolute
  right: 0
  margin-top: 20px
  width: ((100% - $form-labelwidth) - $formtastic-fieldwidth) * 0.8
.billing-sidebar
  background-color: $subscription-yellow
  padding: 0 15px
  .sidebar-section
    padding: 15px 0
  .sidebar-separator
    border-top: 1px solid $gray-light
  a
    font-size: smaller

#sidebar-coupon-link
  position: absolute
  bottom: -3em
.redeem-button
  margin-top: 10px
  text-align: right
  
.annual-savings
  color: white
  background-color: red
  font-size: 85%
  border-radius: 3px
  padding: 0 15px
div.annual-savings
  display: inline-block
  margin: 0.5em 0
.table-edit-button
  text-align: right
  width: $formtastic-fieldwidth
  margin-left: $form-labelwidth
  margin-top: -32px
