.location-editor

  &__input-select
    margin-bottom: 1em
    > .cfnext-form-option
      margin-bottom: 0

    // TODO Overide .entity-editor__field-group styles that replicate
    // legacy behavior
    label
      padding-bottom: 0 !important

  &__coordinates
    display: flex;

  &__coord-input
    display: flex
    align-items: baseline
    width: 50%
    &:not(:last-child)
      margin-right: 1em

  &__coord-label
    margin-right: 1em

  &__map
    display: block
    margin-bottom: 1em

  &__google-map
    position:relative
    height: 300px
    [aria-hidden=true]
      display: initial

  &__address-search
    position: relative
