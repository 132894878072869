$tab-marker($color)
  &:before
    content: ""
    position: absolute
    background: $color
    bottom: 0
    left: 0
    right: 0
    height: 3px

$tab-space = (10 / 14) * 1rem
$tab()
  color: $colors.text-dark
  white-space: nowrap
  position: relative
  line-height: 4em
  cursor: pointer
  margin: 0 (4*$tab-space) 0 0
  &:last-child
    margin-right: 0
  padding: 0 $tab-space

  &:hover
    $tab-marker($colors.light-blue)
  &[aria-selected=true]
    font-weight: bold
    $tab-marker($colors.blue-mid)


$tab-list()
  display: flex
  [role=tab]
    $tab()

.tab-list
  $tab-list()

[role="tabpanel"][aria-hidden="true"]
  display: none !important

// TODO move this somewhere more fitting
// It is also a bad idea to have this unqualified
[aria-hidden=true]
  display: none;
