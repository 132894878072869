.authors-help

  &__link
    cursor: pointer
    color: $colors.white
    padding-right: 30px

    // to match other icons in the navbar
    @media (max-width: $nav-bar-break-width)
      svg
        display: none

    .icon-svg-info-stroke
      transition: all 0.1s ease-in-out

    &:hover
      .icon-svg-info-stroke
        stroke: $colors.blue-mid

    &-container
      height: 100%
      display: flex
      align-items: center

    &-icon
      display: inline-block
      margin-right: 6px
      height: 23px

  &__modal
    line-height: 1.5em
    width: 750px
    border-radius: 3px
    text-align: center

  &__banner
    width: 100%
    height: 154px

  &__container
    padding: 32px

  &__title
    font-size: 22px
    margin-top: 0
    margin-bottom: 12px

  &__description
    color: $colors.text-mid
    font-size: 15px
    font-size: 16px
    padding-bottom: 17px

  &__line
    height: 1px
    width: 458px
    background: $colors.element-light
    margin-left: auto
    margin-right: auto
    margin-bottom: 23px

  &__list
    position: relative
    left: 15px
    text-align: left
    width: 450px
    margin-left: auto
    margin-right: auto
    font-size: 16px

    &-elem
      list-style: disc
      margin-bottom: 7px

  &__feedback
    position: absolute
    display: flex
    align-items: center
    padding-top: 10px

    &-container
      display: flex
      align-items: center

    &-choice
      margin-left: 8px
      border: 1px solid #C4CDD5
      border-radius: 2px
      background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%)
      height: 26px
      width: 26px
      cursor: pointer
      display: flex
      align-items: center
      justify-content: center

      &:hover
        background: linear-gradient(180deg, #FFFFFF 0%, darken(#F9FAFB, 5%) 100%)

  &__footer
    margin-top: 67px


