// Dropdown base
.dropdown-toggle
  cursor: pointer

$separator-style = 1px solid $gray-lightest

$dropdown-row()
  padding: 4px 12px
  display: block

.dropdown-menu
  $layer('dropdown')
  position: absolute
  min-width: 198px
  line-height: 2.2em
  padding-top: 4px
  padding-bottom: 4px
  text-align: left
  font-size: 11px
  background: white
  background: rgba(255,255,255,0.96)
  border: 1px solid shade(#FFFFFF, 10)
  box-shadow: 0 2px 6px rgba(0,0,0,0.1)
  border-radius: 0 0 3px 3px

.lang-switcher
  $scroll-vertically(300px)

$dropdown-menu-item()
  $dropdown-row()
  margin: 0
  a
    display: block
  a:link,
  a:visited
    color: $gray-medium
  label
    cursor: pointer
  &.top-separator
    height: auto
    background: inherit
    border-top: $separator-style
  &.bottom-separator
    border-bottom: $separator-style
  &.hint
    color: $gray
  &:hover, &.hover
    cursor: pointer
    color: white
    $highlight($blue)
    a:link
      color: white

.dropdown-menu-item
  $dropdown-menu-item()

.dropdown-menu-item--label
  $dropdown-menu-item()
  padding: 0
  > label
    $dropdown-row()

.dropdown-menu-item--no-highlight
  $dropdown-menu-item()
  &:hover,
  & > li:hover
    background: none
  &.has-no-action
    &:hover,
    & > li:hover
      cursor: default

// Positioning/Caret stuff
.dropdown-menu.top-caret,
.dropdown-menu.bottom-caret,
.dropdown-menu.left-caret,
.dropdown-menu.right-caret
  border-radius: 3px

.dropdown-menu.left-aligned:before
  left: $caret-size + 10px
.dropdown-menu.right-aligned:before
  right: $caret-size + 10px
.dropdown-menu.center-aligned:before
  left: 50%

.top-caret:before
  $caret()
  content: "\f0d8";
  top: - $caret-size - 4px
  text-shadow: 0px -2px 3px rgba(0,0,0,0.12)

.bottom-caret:before
  $caret()
  content: "\f0d7";
  bottom: - $caret-size - 6px
  text-shadow: 0px 2px 3px rgba(0,0,0,0.12)

.left-caret:before
  $caret()
  content: "\f0d9";
  left: - $caret-size + 2px
  text-shadow: -2px 0px 3px rgba(0,0,0,0.12)

.right-caret:before
  $caret()
  content: "\f0da";
  right: - $caret-size + 2px
  text-shadow: 2px 0px 3px rgba(0,0,0,0.12)
