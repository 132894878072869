@require './fields.styl'

$ct-editor-code-block()
  display: block
  > pre
    white-space: pre-wrap

.content-type-tab
  display: flex

.content-type-hints
  margin: 0 $rhythm(1)
  max-width: 300px

.ct-editor-json
  &__note
    margin: 0 $rhythm(1) $rhythm(1.5) 0

  &__loading
    background-image: url('/app/images/ajax-loader.gif')
    background-position: center
    background-repeat: no-repeat
    background-size: 25px 25px
    height: 30px

  &__code
    $ct-editor-code-block()

  &__code--unsaved
    $ct-editor-code-block()
    position: relative
    user-select: none
    &:before
      content: ""
      background: linear-gradient(to bottom, hsla(0, 0%, 100%, 0.6), hsla(0, 0%, 100%, 1) 80%)
      $stretch()

cf-icon[name="field-shorttext"],
cf-icon[name="field-shorttext-small"],
cf-icon[name="field-longtext"],
cf-icon[name="field-longtext-small"],
cf-icon[name="field-number"],
cf-icon[name="field-number-small"],
cf-icon[name="field-decimal"],
cf-icon[name="field-decimal-small"],
cf-icon[name="field-boolean"],
cf-icon[name="field-boolean-small"],
cf-icon[name="field-json"],
cf-icon[name="field-json-small"]
  path, circle
    fill: $colors.green-mid
  rect
    stroke: $colors.green-mid
  .x--disabled &, .x--omitted &
    path, circle
      fill: $colors.gray
    rect
      stroke: $colors.gray

cf-icon[name="field-calendar"],
cf-icon[name="field-calendar-small"],
cf-icon[name="field-media"],
cf-icon[name="field-media-small"],
cf-icon[name="field-location"],
cf-icon[name="field-location-small"],
cf-icon[name="field-reference"],
cf-icon[name="field-reference-small"]
  path, circle, rect
    stroke: $colors.green-mid
  .x--disabled &, .x--omitted &
    path, circle, rect
      stroke: $colors.gray

cf-icon[name="field-calendar"]
  rect
    fill: $colors.green
  rect:first-of-type
    fill: none

cf-icon[name="field-media"]
  rect
    fill: $colors.green-mid
  rect:last-of-type
    fill: none
