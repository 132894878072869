// Need to use 'a' selector to overide globally set link colors
a.knowledge-base-link
  display: inline-block
  position: relative
  color: $colors.blue-darkest
  text-decoration: underline
  cursor: pointer
  &:hover
    color: $colors.text-dark
  .fa-question-circle
    display: inline-block
  .tooltip-inner
    width: 200px
  &.x--no-text
    color: $colors.text-lightest
    &:hover
      color: $colors.text-light
  &.x--inline
    display: inline
    > i.fa-question-circle
      display: none
