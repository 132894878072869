$stretch($top = 0, $right = 0, $bottom = 0, $left = 0)
  position: absolute
  if type($top) is 'unit'
    top: $top
  if type($right) is 'unit'
    right: $right
  if type($bottom) is 'unit'
    bottom: $bottom
  if type($left) is 'unit'
    left: $left

$stretch-x($left = 0, $right = 0)
  $stretch(false, $right, false, $left)

$stretch-y($top = 0, $bottom = 0)
  $stretch($top, false, $bottom, false)


$pie-clearfix()
  &:after
    content: "\0020";
    display: block;
    height: 0;
    clear: both;
    overflow: hidden;
    visibility: hidden;


