
.boilerplate-page-contact-us

  &__container
    color: $colors.text-mid
    border: 1px solid $colors.ice-dark
    padding: 0.75em 2em
    margin-top: -2.5em
    text-align: center

  &__title
    margin-bottom: 15px
    font-size: 18px

  &__description
    margin-bottom: 16px

  &__img
    display: block
    margin: 20px auto -10px
    width: 326px
    height: 114px
    background-size: contain
    background-image: url('/app/images/faces/contact-experts-boilerplate.jpg')
