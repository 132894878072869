.entity-list-link,
.entity-list-link:link
  display: block
  padding: 8px 0
  color: $base-font-color
  white-space: no-wrap
  overflow: hidden
  text-overflow: ellipsis

.entity-list-badge
  margin-left: $rhythm(1)
  color: $colors.text-light
  font-size: .85em
  text-transform: uppercase
